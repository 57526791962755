import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';

/**
 * 代理onClick 返回promise 则有loading 状态
 * @param props
 * @constructor
 */
export const LoadingButton: React.FC<ButtonProps> = React.forwardRef<HTMLBaseElement, ButtonProps>(
  (props, ref) => {
    const [loading, setLoading] = React.useState(false);
    return (
      <Button
        ref={ref}
        loading={loading}
        {...props}
        onClick={e => {
          if (props.onClick) {
            const p = props.onClick.call(null, e) as void | Promise<any>;
            if (p instanceof Promise) {
              setLoading(true);
              Promise.resolve(p).finally(() => setLoading(false));
            }
          }
        }}
      />
    );
  }
);
