import React from 'react';
import { viewportContext } from './viewportContext';
import { debounce } from 'lodash';

const ViewportProvider: React.FC = ({ children }) => {
  // 顺带监听下高度，备用
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const handleWindowResize = debounce(
    () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    },
    500,
    { leading: false, trailing: true }
  );

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <viewportContext.Provider value={{ width, height }}>{children}</viewportContext.Provider>;
};

export { ViewportProvider };
