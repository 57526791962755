import React from 'react';
import { Redirect } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { RouterProps } from '../types';

export interface RenderRoutesProps {
  routes?: RouterProps[];
  noMatchPage?: React.ReactNode;
}

function RenderRoutes(props: RenderRoutesProps) {
  const { routes = [], noMatchPage } = props;

  function getNoMatches() {
    return noMatchPage ? [{ render: () => noMatchPage }] : [];
  }

  function getRoutes() {
    return routes.map(r => {
      if (r.redirect) {
        const { redirect, ...params } = r;
        return { ...params, render: () => <Redirect to={r.redirect!} /> };
      } else {
        return { ...r };
      }
    });
  }
  // @ts-ignore
  return renderRoutes([...getRoutes(), ...getNoMatches()]);
}

export default RenderRoutes;
