import { computeStackTrace } from './tracekit';
import { isErrorEvent, isString } from '../../utils';
import { ILog } from '../../core/types/log';
import { Severity } from '../../core/types/severity';
import { StackFrame } from '../../core/types/stacktrace';

export function exceptionHandler(e: Error): ILog {
  const { failed, ...exception } = computeStackTrace(e);
  return {
    ...exception,
    level: Severity.ERROR,
  };
}

export function messageHandler(e: string): ILog {
  const ERROR_TYPES_RE = /^(?:[Uu]ncaught (?:exception: )?)?(?:((?:Eval|Internal|Range|Reference|Syntax|Type|URI|)Error): )?(.*)$/i;

  let message = e;
  let name = 'Error';

  const groups = e.match(ERROR_TYPES_RE);
  if (groups) {
    name = groups[1];
    message = groups[2];
  }

  return {
    level: Severity.ERROR,
    name,
    message,
  };
}

export function handleErrorWithoutStack(event: any): ILog {
  const ERROR_TYPES_RE = /^(?:[Uu]ncaught (?:exception: )?)?(?:((?:Eval|Internal|Range|Reference|Syntax|Type|URI|)Error): )?(.*)$/i;

  let message = isErrorEvent(event) ? event.message : event;
  let name = 'Error';

  if (isString(message)) {
    const groups = message.match(ERROR_TYPES_RE);
    if (groups) {
      name = groups[1];
      message = groups[2];
    }
  }

  return {
    level: Severity.ERROR,
    name,
    message,
  };
}

export function handleInCompleteRejection(error: string): ILog {
  return {
    level: Severity.ERROR,
    name: 'UnhandledRejection',
    message: `Non-Error promise rejection captured with value: ${error}`,
  };
}

export function handleRejection(error: any): ILog {
  let stack: StackFrame[] = [];
  let message: string;
  if (error.stack) {
    try {
      stack = parseErrorStack(error.stack);
    } catch (e) {}
  }

  try {
    if (error instanceof Error) {
      message = error.toString();
    } else {
      message = JSON.stringify(error);
    }
  } catch (e) {
    message = error;
  }

  return {
    level: Severity.ERROR,
    name: 'UnhandledRejection',
    message,
    stack,
  };
}

export function parseErrorStack(stack: any): StackFrame[] {
  const stackArr = stack.split('at');
  // 只取第一个堆栈信息，获取包含url、line、col的部分，如果有括号，去除最后的括号
  const info = stackArr[1].match(/\/\/.*/)[0].replace(/\)$/, '');
  // 以冒号拆分
  const errorInfoArr = info.split(':');
  const len = errorInfoArr.length;
  // 行号、列号在最后位置
  const column = errorInfoArr[len - 1];
  const line = errorInfoArr[len - 2];
  // 删除最后两个（行号、列号）
  errorInfoArr.splice(len - 2, 2);
  const url = errorInfoArr.join(':');
  return [
    {
      column,
      line,
      url,
    },
  ];
}
