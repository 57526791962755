import { Http, InterceptorHttp } from '@pinweb/http';
import { logger } from '@pinweb/logger';

export const PIN_ADDRESS_PATH = 'https://oss.pinquest.cn/district_codev2.json';

export interface ChainAreaDataRsp {
  [areaCode: number]: {
    /**
     * 0，中文
     * 1，拼音
     */
    [areaCode: number]: [string, string];
  };
}

/**
 * 地区树
 * 当没有下级 children 不存在
 */
export interface ChainAreaTreeItem {
  code: number;
  name: string;
  /** 兼容字段,大部分选择器是label value **/
  /** 有需要可以做成自定义字段名 **/
  label: string;
  value: number;
  namePinYin: string;
  children?: ChainAreaTreeItem[];
}

/**
 * 根据通用cdn地区数据生成地区树
 * @param data
 * @param code
 * @param arr
 */
export const generateAreaTree = (
  data: ChainAreaDataRsp,
  code: number,
  arr: ChainAreaTreeItem[] = []
): ChainAreaTreeItem[] => {
  if (Object.prototype.hasOwnProperty.call(data, code)) {
    Object.keys(data[code]).forEach(areaCode => {
      const current: [string, string] = data[code][+areaCode];
      const item: ChainAreaTreeItem = {
        code: +areaCode,
        name: current[0],
        label: current[0],
        value: +areaCode,
        namePinYin: current[1],
        children: [],
      };
      generateAreaTree(data, +areaCode, item.children!);
      arr.push(item);
      if (!item.children!.length) {
        delete item.children;
      }
    });
  }
  return arr;
};

/**
 * 品快通用省市区数据
 * @default 默认web端xhr适配器
 * @param http 传入当前http适配器
 */
export async function getChainAreaTree(http: Http | InterceptorHttp = new Http()) {
  return http
    .get(PIN_ADDRESS_PATH, { skipInterceptor: true })
    .then(res => generateAreaTree(res.json(), 86))
    .catch(err => {
      logger.captureException(err);
      return Promise.reject(err);
    });
}
