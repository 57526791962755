import {
  HEADER_AUTHORIZATION_KEY,
  getBrickRPCClientInstance,
  injectBrickRPCResponseInterceptor,
  registerAPIServiceClient,
  IClientOptions,
} from '@pinweb/services';
import errorMessage from './errorMessageServices';
import { persistUserStore } from '@/stores';
import { InterceptorRequest, Response } from '@pinweb/http';

export function setApiClient() {
  const httpInterceptor = getBrickRPCClientInstance();

  httpInterceptor.interceptor.request.use(async function (req) {
    const { auth = '1' } = req.extra as IClientOptions;
    if (auth === '1') {
      try {
        const { sid } = await persistUserStore.then(store => store.handleLoginResp());
        req.headers.set(HEADER_AUTHORIZATION_KEY, sid);
      } catch (error) {
        this.lock();
        // TODO relogin
        this.clear();
      }
    }
    return req;
  });

  httpInterceptor.interceptor.response.use(
    res => res,
    async function (err) {
      if (err instanceof Response && err.json()) {
        const data = err.json();
        const code = data.errcode || data.code || 0;
        const errorText = data.errmsg || data.error || 'system error';
        const { toast = true } = (err!.request as InterceptorRequest).extra;

        if (toast) {
          errorMessage(code, errorText);
        }
        return Promise.reject(data);
        /** 超时 */
      } else if (typeof err === 'string' && /Request timed out/g.test(err)) {
        errorMessage(-1, 'request timeout');
      } else {
        errorMessage(-1, 'System Error');
      }
      return Promise.reject(err);
    }
  );

  injectBrickRPCResponseInterceptor(httpInterceptor);

  registerAPIServiceClient(httpInterceptor);
}
