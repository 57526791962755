import { IScope } from './types/scope';
import { IBreadcrumb } from './types/breadcrumb';

export class Scope implements IScope {
  private _breadcrumbs: IBreadcrumb[] = [];
  private _ext: { [key: string]: string } = {};

  addBreadcrumb(breadcrumb: IBreadcrumb) {
    this._breadcrumbs.push(breadcrumb);
  }

  removeBreadcrumb() {
    this._breadcrumbs.shift();
  }

  clearBreadcrumbs() {
    this._breadcrumbs = [];
  }

  getBreadcrumbs(): IBreadcrumb[] {
    return this._breadcrumbs;
  }

  addExt(key: string, value: any) {
    this._ext[key] = value;
  }

  setExt(ext: { [key: string]: any }) {
    this._ext = ext;
  }

  getExt() {
    return this._ext;
  }
}
