import { Severity } from './severity';
import { RequestOptions } from '@pinweb/http';
import { IExportData } from './log';

export enum CatType {
  term_debug,
  term_report,
}

export interface IOptions {
  catType?: keyof typeof CatType;
  logLevel?: keyof typeof Severity;
  uploadServer?: string;
  release?: string;
  environment?: string;
  maxBreadcrumbs?: number;
  maxLogNum?: number;
  sampleRate?: number;
  autoUpload?: boolean;
  uploadDelay?: number;
  /**
   * 上报前处理数据
   * @param options
   */
  processRequestOptions?(options: RequestOptions): RequestOptions;
  /**
   * 上传前参数处理
   * @param data
   */
  processRequestData?(data: IExportData[]): IExportData[];
}
