import React from 'react';
import RPT from 'prop-types';

interface ILoadScriptProps {
  attributes: Partial<HTMLScriptElement>;
  onCreate: () => void;
  onError: () => void;
  onLoad: () => void;
  url: string;
}

class LoadScript extends React.Component<ILoadScriptProps> {
  static propTypes = {
    attributes: RPT.object,
    onCreate: RPT.func,
    onError: RPT.func.isRequired,
    onLoad: RPT.func.isRequired,
    url: RPT.string.isRequired,
  };

  static defaultProps: Partial<ILoadScriptProps> = {
    onCreate: () => {},
    onError: () => {},
    onLoad: () => {},
  };

  // A dictionary mapping script URLs to a dictionary mapping
  // component key to component for all components that are waiting
  // for the script to load.
  static scriptObservers: {
    [url: string]: {
      [scriptLoaderId: string]: ILoadScriptProps;
    };
  } = {};

  // A dictionary mapping script URL to a boolean value indicating if the script
  // has already been loaded.
  static loadedScripts = {};

  // A dictionary mapping script URL to a boolean value indicating if the script
  // has failed to load.
  static erroredScripts = {};

  // A counter used to generate a unique id for each component that uses
  // ScriptLoaderMixin.
  static idCount = 0;

  scriptLoaderId: string = '';

  constructor(props: ILoadScriptProps) {
    super(props);
    this.scriptLoaderId = `id${LoadScript.idCount++}`; // eslint-disable-line space-unary-ops, no-plusplus
  }

  componentDidMount() {
    const { onError, onLoad, url } = this.props;

    if (LoadScript.loadedScripts[url]) {
      onLoad();
      return;
    }

    if (LoadScript.erroredScripts[url]) {
      onError();
      return;
    }

    // If the script is loading, add the component to the script's observers
    // and return. Otherwise, initialize the script's observers with the component
    // and start loading the script.
    if (LoadScript.scriptObservers[url]) {
      LoadScript.scriptObservers[url][this.scriptLoaderId] = this.props;
      return;
    }

    LoadScript.scriptObservers[url] = {
      [this.scriptLoaderId]: this.props,
    };

    this.createScript();
  }

  componentWillUnmount() {
    const { url } = this.props;
    const observers = LoadScript.scriptObservers[url];

    // If the component is waiting for the script to load, remove the
    // component from the script's observers before unmounting the component.
    if (observers) {
      delete observers[this.scriptLoaderId];
    }
  }

  createScript() {
    const { onCreate, url, attributes } = this.props;
    const script = document.createElement('script');

    onCreate();

    // add 'data-' or non standard attributes to the script tag
    if (attributes) {
      Object.keys(attributes).forEach(prop => script.setAttribute(prop, attributes[prop]));
    }

    script.src = url;

    // default async to true if not set with custom attributes
    if (!script.hasAttribute('async')) {
      script.async = true;
    }

    const callObserverFuncAndRemoveObserver = (
      shouldRemoveObserver: (observer: ILoadScriptProps) => boolean
    ) => {
      const observers = LoadScript.scriptObservers[url];
      Object.keys(observers).forEach(key => {
        if (shouldRemoveObserver(observers[key])) {
          delete LoadScript.scriptObservers[url][this.scriptLoaderId];
        }
      });
    };
    script.onload = () => {
      LoadScript.loadedScripts[url] = true;
      callObserverFuncAndRemoveObserver(observer => {
        observer.onLoad();
        return true;
      });
    };

    script.onerror = () => {
      LoadScript.erroredScripts[url] = true;
      callObserverFuncAndRemoveObserver(observer => {
        observer.onError();
        return true;
      });
    };

    document.body.appendChild(script);
  }

  render() {
    return null;
  }
}

export default LoadScript;
