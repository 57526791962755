import React from 'react';
import BraftEditor, { BraftEditorProps, EditorState } from 'braft-editor';
import { mediaParams, converts, defaultControls, MediaParamsOptions } from './services';

export interface IRichTextEditorProps extends BraftEditorProps, MediaParamsOptions {
  convertOutPut?: (editorState: EditorState) => any;
  convertInput?: (input: any) => EditorState;
  toRem?: boolean;
  sizeBase?: number;
  value?: string | EditorState;
  defaultValue?: string | EditorState;
  onChange?: (editorState: EditorState) => void;
  converts?: {
    unitImportFn: IUnitImportFn;
    unitExportFn: IUnitExportFn;
  };
}

export type IUnitImportFn = (unit: string, type: string, source: 'create' | 'paste') => number;

export type IUnitExportFn = (
  unit: number,
  type: string,
  target: 'html' | 'editor'
) => string | number;

abstract class Impl {
  static BraftEditor: typeof BraftEditor;
}

class RichTextEditor extends React.Component<IRichTextEditorProps> implements Impl {
  static defaultProps = {
    sizeBase: 23.4375,
  };

  static BraftEditor = BraftEditor;

  content = '';

  exportConvertFn: IUnitExportFn = (unit: number, type: string, target: 'html' | 'editor') => {
    if (type === 'line-height') {
      // 输出行高时不添加单位
      return unit;
    }

    // target的值可能是html或者editor，对应输出到html和在编辑器中显示这两个场景
    if (target === 'html') {
      // 只在将内容输出为html时才进行转换
      return unit / this.props.sizeBase! + 'rem';
    } else {
      // 在编辑器中显示时，按px单位展示
      return unit + 'px';
    }
  };

  importConvertFn: IUnitImportFn = (unit: string, _type: string, _source: 'create' | 'paste') => {
    // type为单位类型，例如font-size等
    // source为输入来源，可能值为create或paste

    // 此函数的返回结果，需要过滤掉单位，只返回数值
    if (unit.indexOf('rem')) {
      return parseFloat(unit) * this.props.sizeBase!;
    } else {
      return parseFloat(unit);
    }
  };

  str2EditorState = (value: any) => {
    const { converts, toRem } = this.props;
    try {
      return typeof value === 'string'
        ? BraftEditor.createEditorState(value, {
            unitImportFn: toRem
              ? (converts && converts.unitImportFn) || this.importConvertFn
              : null,
          })
        : value;
    } catch (e) {
      console.log(e);
      return value;
    }
  };

  handleOutput = (editorState: EditorState) => {
    const { convertOutPut } = this.props;
    return convertOutPut
      ? convertOutPut(editorState)
      : editorState.isEmpty()
      ? ''
      : editorState.toHTML();
  };

  handleInput = (value: any) => {
    // if typeof input is string,
    // transer to editorstate;
    // else use value directly
    const { convertInput } = this.props;
    try {
      return convertInput ? convertInput(value) : this.str2EditorState(value);
    } catch (e) {
      console.log(e);
      return value;
    }
  };

  triggerChange = (editorState: EditorState) => {
    const output = this.handleOutput(editorState);
    if (output === this.props.value) {
      return;
    }

    this.content = output;
    this.props.onChange && this.props.onChange(output);
  };

  shouldComponentUpdate(nextProps: IRichTextEditorProps, _nextState: IRichTextEditorProps) {
    return nextProps.value !== this.content;
  }

  render() {
    const {
      convertOutPut,
      convertInput,
      sizeBase,
      toRem,
      style,
      value,
      media,
      className,
      defaultValue,
      onChange,
      ...options
    } = this.props;

    return (
      <BraftEditor
        className={className}
        style={{
          border: '1px solid #d1d1d1',
          ...style,
        }}
        value={this.handleInput(value)}
        converts={{
          ...converts,
          ...(toRem
            ? {
                unitImportFn: this.importConvertFn,
                unitExportFn: this.exportConvertFn,
              }
            : {}),
        }}
        defaultValue={this.handleInput(defaultValue)}
        onChange={this.triggerChange}
        controls={defaultControls}
        media={{ ...media, ...mediaParams(this.props) }}
        {...options}
      />
    );
  }
}

export default RichTextEditor;
