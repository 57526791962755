export function ProductionRUN(run: () => void) {
  if (process.env.NODE_ENV === 'production') run();
}

export function StagingRUN(run: () => void) {
  if (process.env.NODE_ENV === 'staging') run();
}

export function DevelopmentRUN(run: () => void) {
  if (process.env.NODE_ENV === 'development') run();
}

export function isProductionMode<T = any>(production: any, otherMode: any): T {
  return process.env.mode === 'production' ? production : otherMode;
}
