import React from 'react';

export interface BaseEmptyProps extends React.PropsWithChildren<{}> {
  title: string;
  img: string;
  height?: string;
  desc?: string;
}

function BaseEmpty(props: BaseEmptyProps): React.ReactElement {
  const { height = '60vh', title, desc, img } = props;

  return (
    <div className="BaseEmpty" style={{ minHeight: height }}>
      <div className={'BaseEmpty-content'}>
        <img className={'BaseEmpty-content-cover'} src={img} alt="" />
        <div className={'BaseEmpty-content-title'}>{title}</div>
        {desc && <div className={'BaseEmpty-content-desc'}>{desc}</div>}
      </div>
      {props.children}
    </div>
  );
}

export default React.memo(BaseEmpty);
