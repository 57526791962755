import { getCurrentHub } from './core';
import { ILog } from './core/types/log';
import { IBreadcrumb } from './core/types/breadcrumb';

export function captureException(error: Error) {
  getCurrentHub().captureException(error);
}

export function debug(log: Omit<ILog, 'level'>) {
  getCurrentHub().debug(log);
}

export function log(log: Omit<ILog, 'level'>) {
  getCurrentHub().log(log);
}

export function info(log: Omit<ILog, 'level'>) {
  getCurrentHub().info(log);
}

export function warn(log: Omit<ILog, 'level'>) {
  getCurrentHub().warn(log);
}

export function error(log: Omit<ILog, 'level'>) {
  getCurrentHub().error(log);
}

export function addBreadcrumb(breadcrumb: IBreadcrumb) {
  getCurrentHub().addBreadcrumb(breadcrumb);
}

export function setExt(data: { [key: string]: string }) {
  getCurrentHub().setExt(data);
}

export function addExt(key: string, value: string) {
  getCurrentHub().addExt(key, value);
}

export function upload() {
  return getCurrentHub().upload();
}
