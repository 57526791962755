import { BaseAccountStore, LoginResp } from '@pinweb/mobx-logic';
import { AdminServices, AccountlogicServices } from '@pinweb/services';
import cookie from 'js-cookie';

class UserStore extends BaseAccountStore<admin.LoginReq, admin.LoginRsp> {
  AccountlogicServices = AccountlogicServices;
  // TODO app login
  async processDoLogin(req: admin.LoginReq): Promise<LoginResp> {
    const { sid, expired_at, ...params } = await AdminServices.Login(req, {
      waitLock: false,
    });
    cookie.remove('sid');
    console.log('TODO', params);
    return {
      sid: sid!,
      expired_at: expired_at!,
    };
  }

  processDoLoginOut(): void {
    console.log('login out');
  }
}

const userStore = new UserStore();

export default userStore;

export { UserStore };
