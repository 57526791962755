import { getGlobalObject } from '../utils';
import { IBreadcrumb } from './types/breadcrumb';
import { ILog } from './types/log';
import { IClient } from './types/client';
import { Scope } from './scope';
import { Severity } from './types/severity';
import { IOptions } from './types/options';
import { Response } from '@pinweb/http';

type ILogOpt = Omit<ILog, 'level'>;
type ILogFN = (log: ILogOpt) => void;

export interface IHub {
  addBreadcrumb(breadcrumb: IBreadcrumb): void;
  captureException(error: Error): void;
  captureMessage(data: ILog): void;
  debug: ILogFN;
  log: ILogFN;
  info: ILogFN;
  warn: ILogFN;
  error: ILogFN;
  addExt(key: string, value: any): void;
  setExt(data: { [key: string]: any }): void;
  upload(): Promise<Response>;
  bindClient(client: IClient): void;
  getScope(): Scope;
}

export const PREFIX = '__PINWEBLOGGER__';

export interface ICarrier {
  [PREFIX]: {
    hub?: IHub;
    [key: string]: any;
  };
}

interface ILayer {
  client?: IClient;
  scope: Scope;
}

const MAX_BREADCRUMBS = 100;

export class Hub implements IHub {
  private readonly _stack: ILayer[] = [];

  constructor(client?: IClient, scope: Scope = new Scope()) {
    this._stack.push({ client, scope });
  }

  private _getStack() {
    return this._stack[this._stack.length - 1];
  }

  addBreadcrumb(breadcrumb: IBreadcrumb) {
    const top = this._getStack();
    const curBreadcrumbsLen = top.scope.getBreadcrumbs().length;
    const customMaxLen = top.client?.getOptions().maxBreadcrumbs || MAX_BREADCRUMBS;

    // 是否超过用户设置的以及绝对的对大储存
    if (curBreadcrumbsLen >= MAX_BREADCRUMBS || curBreadcrumbsLen >= customMaxLen) {
      top.scope.removeBreadcrumb();
    }

    top.scope.addBreadcrumb(breadcrumb);
  }

  private capture(data: ILog) {
    const top = this._getStack();
    top.client?.capture(data);
  }

  captureException(e: Error) {
    const top = this._getStack();
    top.client?.captureException(e);
  }

  captureMessage(data: ILog) {
    const top = this._getStack();
    top.client?.captureMessage(data);
  }

  debug(data: ILogOpt) {
    this.capture({ ...data, level: Severity.DEBUG });
  }

  log(data: ILogOpt) {
    this.capture({ ...data, level: Severity.LOG });
  }

  info(data: ILogOpt) {
    this.capture({ ...data, level: Severity.INFO });
  }

  warn(data: ILogOpt) {
    this.capture({ ...data, level: Severity.WARN });
  }

  error(data: ILogOpt) {
    this.capture({ ...data, level: Severity.ERROR });
  }

  bindClient(client: IClient) {
    this._getStack().client = client;
  }

  addExt(key: string, value: any) {
    const top = this._getStack();
    top.scope.addExt(key, value);
  }

  setExt(data: { [key: string]: any }) {
    const top = this._getStack();
    top.scope.setExt(data);
  }

  upload() {
    const top = this._getStack();
    if (!top.client) {
      return Promise.reject(new Error('Please register a client'));
    }
    return top.client?.upload();
  }

  getScope() {
    const top = this._getStack();
    return top.scope;
  }
}

export function getCurrentHub(): IHub {
  const carrier = getGlobalObject<ICarrier>();
  carrier[PREFIX] = carrier[PREFIX] || {
    hub: undefined,
  };
  if (!carrier[PREFIX].hub) {
    carrier[PREFIX].hub = new Hub();
  }
  return carrier[PREFIX].hub!;
}

export type ClientClass<F extends IClient, O extends IOptions> = new (options: O) => F;

export function initAndBind(Client: ClientClass<IClient, IOptions>, options: IOptions) {
  getCurrentHub().bindClient(new Client(options));
}
