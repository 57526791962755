import React, { useState } from 'react';
import { Dropdown, Tooltip } from 'antd';
import { DropDownProps } from 'antd/es/dropdown';
import {
  CaretDownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from '@ant-design/icons';
import screenfull from 'screenfull';

import {
  HeaderTitle,
  HeaderContainer,
  HeaderContainerLeft,
  HeaderContainerRight,
  HeaderTriggerButton,
  HeaderMenuItem,
  UserAvatar,
} from './header.styled';

export type HeaderProps = {
  /**
   * 伸缩模式
   */
  collapsed?: boolean;

  /**
   * 是否显示可伸缩按钮
   * @default true
   * @note 会检测当前浏览器是否支持，不支持不会显示
   */
  showCollapsedIcon?: boolean;

  /**
   * 显示全屏开关
   * @default true
   */
  showScreenfull?: boolean;

  /**
   * 下拉菜单
   */
  userDropdownOverlay: DropDownProps['overlay'];

  /**
   * 用户名
   */
  userName?: React.ReactNode | string;

  /**
   * 头像
   */
  userAvatar?: string;

  /**
   * 角色名字
   */
  roleName?: string;
  toggleCollapsed?(): void;

  /**
   * 渲染title前的一些按钮
   */
  renderHeaderLeftActions?: React.ReactNode[];

  /**
   * 渲染标题右边内容
   */
  renderHeaderLeft?: React.ReactNode;

  /**
   * 渲染右边内容
   */
  renderHeaderRight?: React.ReactNode;

  /**
   * 头部渲染页面标题
   */
  renderHeaderTitle?: React.ReactNode;
};

const screenfullUtil = screenfull! as screenfull.Screenfull;

function Header(props: HeaderProps) {
  const {
    collapsed,
    toggleCollapsed,
    userDropdownOverlay,
    userName,
    userAvatar,
    renderHeaderLeft,
    renderHeaderRight,
    showCollapsedIcon = true,
    showScreenfull = true,
    renderHeaderTitle,
    renderHeaderLeftActions = [],
  } = props!;

  const [isFullscreen, setFullscreen] = useState<boolean>(screenfullUtil.isFullscreen);

  React.useEffect(() => {
    if (!showScreenfull || !screenfull.isEnabled) {
      return;
    }
    let isSubscribed = true;
    screenfullUtil.on('change', () => {
      if (isSubscribed) {
        setFullscreen(screenfullUtil.isFullscreen);
      }
    });
    return () => {
      isSubscribed = false;
      screenfullUtil.off('change', () => {});
    };
  }, [showScreenfull]);

  return (
    <HeaderContainer className="basis-layout--header">
      <HeaderContainerLeft>
        {showCollapsedIcon ? (
          <HeaderTriggerButton>
            {collapsed ? (
              <MenuUnfoldOutlined
                className="basis-layout--header--collapsed-icon"
                onClick={() => toggleCollapsed && toggleCollapsed()}
              />
            ) : (
              <MenuFoldOutlined
                className="basis-layout--header--collapsed-icon"
                onClick={() => toggleCollapsed && toggleCollapsed()}
              />
            )}
          </HeaderTriggerButton>
        ) : null}
        {renderHeaderLeftActions.map((v, i) => (
          <HeaderTriggerButton key={i}>{v}</HeaderTriggerButton>
        ))}
        {renderHeaderTitle ? (
          <HeaderTitle className="basis-layout--header--title">{renderHeaderTitle}</HeaderTitle>
        ) : null}
        {renderHeaderLeft}
      </HeaderContainerLeft>
      <HeaderContainerRight>
        {renderHeaderRight}
        {screenfull.isEnabled && showScreenfull ? (
          <HeaderMenuItem>
            <Tooltip trigger="hover" title={isFullscreen ? '退出全屏' : '进入全屏'}>
              {isFullscreen ? (
                <FullscreenExitOutlined onClick={() => screenfullUtil.toggle()} />
              ) : (
                <FullscreenOutlined onClick={() => screenfullUtil.toggle()} />
              )}
            </Tooltip>
          </HeaderMenuItem>
        ) : null}
        <Dropdown placement="bottomCenter" overlay={userDropdownOverlay}>
          <HeaderMenuItem>
            {userAvatar ? (
              <UserAvatar src={userAvatar} className="basis-layout--header__user-avatar" alt="" />
            ) : null}
            {userName}
            <CaretDownOutlined style={{ marginLeft: 8 }} />
          </HeaderMenuItem>
        </Dropdown>
      </HeaderContainerRight>
    </HeaderContainer>
  );
}

export default Header;
