import { getCurrentHub } from '../../core';
import { isPrimitive } from '../../utils';
import { messageHandler, exceptionHandler } from './exceptionHandler';

declare namespace my {
  type IAnyObject = Record<string, any>;

  interface GeneralCallbackResult {
    errMsg: string;
  }

  /** 小程序错误事件的回调函数 */
  type OnAppErrorCallback = (
    /** 错误信息，包含堆栈 */
    error: string
  ) => void;

  /** [wx.onError(function callback)](https://developers.weixin.qq.com/miniprogram/dev/api/base/app/app-event/wx.onError.html)
   *
   * 监听小程序错误事件。如脚本错误或 API 调用报错等。该事件与 [`App.onError`](https://developers.weixin.qq.com/miniprogram/dev/reference/api/App.html#onerrorstring-error) 的回调时机与参数一致。
   *
   * 最低基础库： `2.1.2` */
  function onError(
    /** 小程序错误事件的回调函数 */
    callback: OnAppErrorCallback
  ): void;

  interface OnUnhandledRejectionCallbackResult {
    /** 被拒绝的 Promise 对象 */
    promise: Promise<any>;
    /** 拒绝原因，一般是一个 Error 对象 */
    reason: string | Error;
  }
  /** 未处理的 Promise 拒绝事件的回调函数 */
  type OnUnhandledRejectionCallback = (result: OnUnhandledRejectionCallbackResult) => void;
  /** [wx.onUnhandledRejection(function callback)](https://developers.weixin.qq.com/miniprogram/dev/api/base/app/app-event/wx.onUnhandledRejection.html)
   *
   * 监听未处理的 Promise 拒绝事件。该事件与 `App.onUnhandledRejection` 的回调时机与参数一致。
   *
   * **注意**
   *
   *
   * - 安卓平台暂时不支持该事件
   * - 所有的 unhandledRejection 都可以被这一监听捕获，但只有 Error 类型的才会在小程序后台触发报警。
   *
   * 最低基础库： `2.10.0` */
  function onUnhandledRejection(
    /** 未处理的 Promise 拒绝事件的回调函数 */
    callback: OnUnhandledRejectionCallback
  ): void;

  function canIUse(
    /** 使用 `${API}.${method}.${param}.${option}` 或者 `${component}.${attribute}.${option}` 方式来调用 */
    schema: string
  ): boolean;

  interface OnPageNotFoundCallbackResult {
    /** 是否本次启动的首个页面（例如从分享等入口进来，首个页面是开发者配置的分享页面） */
    isEntryPage: boolean;
    /** 不存在页面的路径 (代码包路径) */
    path: string;
    /** 打开不存在页面的 query 参数 */
    query: IAnyObject;
  }
  /** 小程序要打开的页面不存在事件的回调函数 */
  type OnPageNotFoundCallback = (result: OnPageNotFoundCallbackResult) => void;
  /** [wx.onPageNotFound(function callback)](https://developers.weixin.qq.com/miniprogram/dev/api/base/app/app-event/wx.onPageNotFound.html)
   *
   * 监听小程序要打开的页面不存在事件。该事件与 [`App.onPageNotFound`](https://developers.weixin.qq.com/miniprogram/dev/reference/api/App.html#onpagenotfoundobject-object) 的回调时机一致。
   *
   * **注意**
   *
   *
   * - 开发者可以在回调中进行页面重定向，但必须在回调中**同步**处理，异步处理（例如 `setTimeout` 异步执行）无效。
   * - 若开发者没有调用 [wx.onPageNotFound](https://developers.weixin.qq.com/miniprogram/dev/api/base/app/app-event/wx.onPageNotFound.html) 绑定监听，也没有声明 `App.onPageNotFound`，当跳转页面不存在时，将推入微信客户端原生的页面不存在提示页面。
   * - 如果回调中又重定向到另一个不存在的页面，将推入微信客户端原生的页面不存在提示页面，并且不再第二次回调。
   *
   * 最低基础库： `2.1.2` */
  function onPageNotFound(
    /** 小程序要打开的页面不存在事件的回调函数 */
    callback: OnPageNotFoundCallback
  ): void;

  interface GetSystemInfoSyncResult {
    /** 客户端基础库版本
     *
     * 最低基础库： `1.1.0` */
    SDKVersion: string;
    /** 允许微信使用相册的开关（仅 iOS 有效）
     *
     * 最低基础库： `2.6.0` */
    albumAuthorized: boolean;
    /** 设备性能等级（仅Android小游戏）。取值为：-2 或 0（该设备无法运行小游戏），-1（性能未知），>=1（设备性能值，该值越高，设备性能越好，目前最高不到50）
     *
     * 最低基础库： `1.8.0` */
    benchmarkLevel: number;
    /** 蓝牙的系统开关
     *
     * 最低基础库： `2.6.0` */
    bluetoothEnabled: boolean;
    /** 设备品牌
     *
     * 最低基础库： `1.5.0` */
    brand: string;
    /** 允许微信使用摄像头的开关
     *
     * 最低基础库： `2.6.0` */
    cameraAuthorized: boolean;
    /** 用户字体大小（单位px）。以微信客户端「我-设置-通用-字体大小」中的设置为准
     *
     * 最低基础库： `1.5.0` */
    fontSizeSetting: number;
    /** 微信设置的语言 */
    language: string;
    /** 允许微信使用定位的开关
     *
     * 最低基础库： `2.6.0` */
    locationAuthorized: boolean;
    /** 地理位置的系统开关
     *
     * 最低基础库： `2.6.0` */
    locationEnabled: boolean;
    /** 允许微信使用麦克风的开关
     *
     * 最低基础库： `2.6.0` */
    microphoneAuthorized: boolean;
    /** 设备型号 */
    model: string;
    /** 允许微信通知带有提醒的开关（仅 iOS 有效）
     *
     * 最低基础库： `2.6.0` */
    notificationAlertAuthorized: boolean;
    /** 允许微信通知的开关
     *
     * 最低基础库： `2.6.0` */
    notificationAuthorized: boolean;
    /** 允许微信通知带有标记的开关（仅 iOS 有效）
     *
     * 最低基础库： `2.6.0` */
    notificationBadgeAuthorized: boolean;
    /** 允许微信通知带有声音的开关（仅 iOS 有效）
     *
     * 最低基础库： `2.6.0` */
    notificationSoundAuthorized: boolean;
    /** 设备像素比 */
    pixelRatio: number;
    /** 客户端平台 */
    platform: string;
    /** 在竖屏正方向下的安全区域
     *
     * 最低基础库： `2.7.0` */
    safeArea: GetSystemInfoSyncResultSafeAreaResult;
    /** 屏幕高度，单位px
     *
     * 最低基础库： `1.1.0` */
    screenHeight: number;
    /** 屏幕宽度，单位px
     *
     * 最低基础库： `1.1.0` */
    screenWidth: number;
    /** 状态栏的高度，单位px
     *
     * 最低基础库： `1.9.0` */
    statusBarHeight: number;
    /** 操作系统及版本 */
    system: string;
    /** 微信版本号 */
    version: string;
    /** Wi-Fi 的系统开关
     *
     * 最低基础库： `2.6.0` */
    wifiEnabled: boolean;
    /** 可使用窗口高度，单位px */
    windowHeight: number;
    /** 可使用窗口宽度，单位px */
    windowWidth: number;
  }

  interface GetSystemInfoSyncResultSafeAreaResult {
    /** 安全区域右下角纵坐标 */
    bottom: number;
    /** 安全区域的高度，单位逻辑像素 */
    height: number;
    /** 安全区域左上角横坐标 */
    left: number;
    /** 安全区域右下角横坐标 */
    right: number;
    /** 安全区域左上角纵坐标 */
    top: number;
    /** 安全区域的宽度，单位逻辑像素 */
    width: number;
  }

  function getSystemInfoSync(): GetSystemInfoSyncResult;

  interface GetStorageInfoSyncOption {
    /** 当前占用的空间大小, 单位 KB */
    currentSize: number;
    /** 当前 storage 中所有的 key */
    keys: string[];
    /** 限制的空间大小，单位 KB */
    limitSize: number;
  }

  function getStorageInfoSync(): GetStorageInfoSyncOption;
}

export function globalErrorHandler() {
  if (my.canIUse('onError')) {
    my.onError(function (err) {
      try {
        getCurrentHub().captureMessage(messageHandler(err));
      } catch (e) {
        console.log('wx.onError captureMessage message error', e);
      }
    });
  }
  if (my.canIUse('onUnhandledRejection')) {
    my.onUnhandledRejection(function (err) {
      try {
        const exception = isPrimitive(err.reason)
          ? messageHandler(err.reason as string)
          : exceptionHandler(err.reason as Error);
        getCurrentHub().captureMessage(exception);
      } catch (e) {
        console.log('wx.onUnhandledRejection captureMessage message error', e);
      }
    });
  }
  if (my.canIUse('onPageNotFound')) {
    my.onPageNotFound(res => {
      getCurrentHub().error({
        name: 'PageNotFound',
        message: JSON.stringify(res),
      });
    });
  }
}

export function addSystemInfoEXT() {
  const info = my.getSystemInfoSync();
  getCurrentHub().addExt('systemInfo', info);
}

export function checkStorage() {
  const { currentSize, limitSize, keys } = my.getStorageInfoSync();
  if (currentSize > limitSize * 0.9) {
    getCurrentHub().error({
      name: `Storage over ${currentSize}`,
      message: `limit:${limitSize} current:${currentSize} keys:${keys}`,
    });
  }
}
