import { RouterProps } from '@/libs/router/types';
import { loadable } from '@/services/loadableServices';

const routes: RouterProps[] = [
  {
    path: '/',
    redirect: '/index',
    exact: true,
  },
  {
    path: '/',
    component: loadable(
      () =>
        import(
          /* webpackPrefetch: true */
          '@/layouts/index/index'
        )
    ),
    children: [
      {
        path: '/index',
        meta: { title: '首页' },
        component: loadable(
          () =>
            import(
              /* webpackPrefetch: true */
              'pages/index/index'
            )
        ),
      },
      {
        path: '/private-flow',
        meta: { title: '私域运营' },
        /* webpackPrefetch: true */
        component: loadable(() => import('@/pages/privateFlow/index')),
      },
      {
        path: '/live-program',
        meta: { title: '直播小程序' },
        /* webpackPrefetch: true */
        component: loadable(() => import('@/pages/liveProgram/index')),
      },
      {
        path: '/custom-made',
        meta: { title: '方案定制' },
        /* webpackPrefetch: true */
        component: loadable(() => import('@/pages/customMade/index')),
      },
      {
        path: '/service-advantage',
        meta: { title: '服务及优势' },
        /* webpackPrefetch: true */
        component: loadable(() => import('@/pages/serviceAndAdvantage/index')),
      },
      {
        path: '/about',
        meta: {
          title: '关于我们',
        },
        /* webpackPrefetch: true */
        component: loadable(() => import('pages/about/index')),
      },
    ],
  },
];

export default routes;
