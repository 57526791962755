import { uuid } from '@pinweb/utils';

export const DEVICE_ID_KEY = 'pin-device-id';

declare namespace wx {
  function getStorageSync(
    /** 本地缓存中指定的 key */
    key: string
  ): any;

  function setStorageSync(
    /** 本地缓存中指定的 key */
    key: string,
    /** 需要存储的内容。只支持原生类型、Date、及能够通过`JSON.stringify`序列化的对象。 */
    data: any
  ): void;
}

declare namespace tt {
  function getStorageSync(
    /** 本地缓存中指定的 key */
    key: string
  ): any;

  function setStorageSync(
    /** 本地缓存中指定的 key */
    key: string,
    /** 需要存储的内容。只支持原生类型、Date、及能够通过`JSON.stringify`序列化的对象。 */
    data: any
  ): void;
}

declare namespace my {
  interface GetStorageSyncOptions {
    /** 本地缓存中指定的 key */
    key: string;
  }
  interface GetStorageSyncResult {
    data: any;
    error?: string;
  }
  function getStorageSync(options: GetStorageSyncOptions): GetStorageSyncResult;

  function setStorageSync(
    /** 本地缓存中指定的 key */
    key: string,
    /** 需要存储的内容。只支持原生类型、Date、及能够通过`JSON.stringify`序列化的对象。 */
    data: any
  ): void;
}

export function handlerTTDeviceID() {
  try {
    const deviceID = tt.getStorageSync(DEVICE_ID_KEY);
    if (deviceID) return deviceID;
  } catch (e) {}
  const deviceID = uuid();
  tt.setStorageSync(DEVICE_ID_KEY, deviceID);
  return deviceID;
}

export function handlerWXDeviceID() {
  try {
    const deviceID = wx.getStorageSync(DEVICE_ID_KEY);
    if (deviceID) return deviceID;
  } catch (e) {}
  const deviceID = uuid();
  wx.setStorageSync(DEVICE_ID_KEY, deviceID);
  return deviceID;
}

export function handlerMYDeviceID() {
  try {
    const { data, error } = my.getStorageSync({ key: DEVICE_ID_KEY });
    if (!error && data) return data;
  } catch (e) {}
  const deviceID = uuid();
  my.setStorageSync(DEVICE_ID_KEY, deviceID);
  return deviceID;
}

export function handlerBrowserDeviceID() {
  try {
    const deviceID = window.localStorage.getItem(DEVICE_ID_KEY)!;
    if (deviceID) return deviceID;
  } catch (e) {}
  const deviceID = uuid();
  window.localStorage.setItem(DEVICE_ID_KEY, deviceID);
  return deviceID;
}
