import { UploadFile } from 'antd/lib/upload/interface';

type IMeasureImgResult = {
  height: number;
  width: number;
};

function measureImgResolution(file: UploadFile): PromiseLike<IMeasureImgResult> {
  return new Promise((resolve, reject) => {
    try {
      const img = document.createElement('img');
      const reader = new FileReader();
      const size: IMeasureImgResult = { height: 0, width: 0 };
      img.onload = () => {
        size.height = img.naturalHeight;
        size.width = img.naturalWidth;
        resolve(size);
      };
      img.onerror = e => {
        reject(e);
      };
      reader.addEventListener('load', () => {
        img.src = reader.result as string;
      });
      // @ts-ignore
      reader.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });
}

export { measureImgResolution };
