import React from 'react';

import { hasOwnProperty } from '../utils';
import { connect } from '../store';
import { SchemaProviderState } from '../provider';
import { SchemaComponentProps } from './interfaces';

export const SchemaComponent = connect<
  { _components: SchemaProviderState['$components'] },
  SchemaComponentProps,
  SchemaProviderState
>(({ $components: _components }) => ({ _components }), { forwardRef: true })(
  React.forwardRef((props, ref) => {
    const { _components, ..._props } = props;

    const renderChild = React.useCallback(
      ({
        _render,
        _component,
        _children,
        ...otherProps
      }: SchemaComponentProps): React.ReactNode => {
        let children: React.ReactNode;

        const renderChildConfig = (_children: SchemaComponentProps['_children']) => {
          if (_children) {
            if (typeof _children === 'string') {
              children = <>{_children}</>;
              // 配置为一个配置数组
            } else if (_children instanceof Array) {
              children = _children.map((v, i) =>
                renderChild({
                  key: i,
                  ...v,
                })
              );
              // 当配置为一个函数时
            } else if (typeof _children === 'function') {
              renderChildConfig(_children.call(null, otherProps));
            } else {
              children = renderChild(_children);
            }
          }
        };
        if (_children) {
          renderChildConfig(_children);
        } else if (otherProps.children) {
          children = otherProps.children;
        }
        if (_render) {
          return _render.call({ ...otherProps, children });
        } else if (_component) {
          if (typeof _component === 'string') {
            if (hasOwnProperty(_components, _component)) {
              const Component = _components[_component];
              return <Component ref={ref} {...{ ...otherProps, children }} />;
            } else {
              throw new Error(`The component is not registered [${_component}]`);
            }
          } else {
            const Component = _component;
            // @ts-ignore
            return <Component ref={ref} {...{ ...otherProps, children }} />;
          }
        } else {
          return <>{children}</>;
        }
      },
      [_components, ref]
    );

    return <>{renderChild(_props)}</>;
  })
);
