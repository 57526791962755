import styled from 'styled-components';

export const MenuContainer = styled.div`
  min-height: 100vh;
  transition: width 0.4s ease-in-out;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  background: ${props => props.theme.menuBGColor};
  position: relative;
  z-index: 10;
`;

export const MenuHeader = styled.div`
  padding: 0 20px;
  background: ${props => props.theme.menuLogoBGColor};
  color: #fff;
  text-align: center;
  height: ${props => props.theme.headerHeight};
  line-height: ${props => props.theme.headerHeight};
`;
