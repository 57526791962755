import React from 'react';
import { InputProps } from 'antd/lib/input';

type WithCompositionProps = InputProps & {
  enable?: boolean;
  outputFormatter?: (res: React.ChangeEvent<HTMLInputElement>) => any;
  inputFormatter?: (res: any) => any;
};

function WithComposition(props: WithCompositionProps) {
  const {
    enable = true,
    children,
    value: propValue,
    onChange,
    onBlur,
    onCompositionEnd,
    onCompositionStart,
    inputFormatter,
    outputFormatter,
    ...extProps
  } = props;

  const isCompositionRef = React.useRef(false);
  const isChrome = React.useMemo(() => navigator.userAgent.indexOf('Chrome') > -1, []);
  const [compositionValue, setCompositionValue] = React.useState<any>();
  const value = React.useMemo(() => {
    const val = isCompositionRef.current ? compositionValue : propValue;
    return inputFormatter ? inputFormatter(val as string) : val;
  }, [compositionValue, inputFormatter, propValue]);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isCompositionRef.current) {
        return setCompositionValue(e.target.value);
      }

      const outputEvt = outputFormatter ? outputFormatter(e) : e;

      onChange && onChange(outputEvt);

      const _compositionVal = outputEvt?.target?.value || outputEvt;

      setCompositionValue(_compositionVal);
    },
    [onChange, outputFormatter]
  );

  const handleComposition = React.useCallback(
    (e: React.CompositionEvent<HTMLInputElement>) => {
      if (e.type === 'compositionend') {
        isCompositionRef.current = false;
        onCompositionEnd && onCompositionEnd(e);

        // chrome：compositionstart onChange compositionend
        // firefox：compositionstart compositionend onChange
        if (isChrome) {
          const evt: any = { ...e, type: 'change' };
          handleChange(evt);
        }
      } else {
        isCompositionRef.current = true;
        onCompositionStart && onCompositionStart(e);
      }
    },
    [handleChange, isChrome, onCompositionEnd, onCompositionStart]
  );

  const handleBlur = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const evt = outputFormatter ? outputFormatter(e) : e;
      onBlur && onBlur(evt);
    },
    [onBlur, outputFormatter]
  );

  const renderProps = React.useMemo(
    () =>
      enable
        ? {
            ...extProps,
            value,
            onChange: handleChange,
            onCompositionStart: handleComposition,
            onCompositionEnd: handleComposition,
            onBlur: handleBlur,
          }
        : {
            ...extProps,
            value,
            onChange,
            onCompositionStart,
            onCompositionEnd,
            onBlur: handleBlur,
          },
    [
      value,
      enable,
      extProps,
      handleBlur,
      handleChange,
      handleComposition,
      onChange,
      onCompositionEnd,
      onCompositionStart,
    ]
  );

  return children ? React.cloneElement(children as any, renderProps) : <></>;
}

export default React.memo(WithComposition);
