import { initAndBind } from '../../core';
import { IOptions } from '../../core/types/options';
import { BrowserClient } from './client';
import {
  globalErrorHandler,
  globalUnhandledRejectionHandler,
  addSystemInfoEXT,
} from './integrations/globalHandlers';
import { catchClickQueue } from './integrations/breadcrumbs';

export function mount(options: IOptions) {
  try {
    initAndBind(BrowserClient, options);
    if (typeof window === 'object') {
      globalErrorHandler();
      globalUnhandledRejectionHandler();
      catchClickQueue();
      addSystemInfoEXT();
    }
  } catch (e) {
    console.log('mount logger error', e);
  }
}
