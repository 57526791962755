import { computeStackTrace } from './tracekit';
import { Severity } from '../../core/types/severity';
import { ILog } from '../../core/types/log';

export function exceptionHandler(e: Error): ILog {
  const { ...exception } = computeStackTrace(e);
  return {
    ...exception,
    level: Severity.ERROR,
  };
}

export function messageHandler(e: string): ILog {
  const { ...exception } = computeStackTrace(e);
  return {
    ...exception,
    level: Severity.ERROR,
  };
}
