import React from 'react';

export type WarpComponentWithFormatterProps<I = any, O = any> = {
  /**
   * 以下两个属性不要传入
   */
  value?: I;
  onChange?: (...args: any[]) => void;

  /**
   * 自定义绑定值的propName
   * @default value
   */
  valuePropName?: string;

  /**
   * 传入数据转换
   * @param value
   */
  inputFormatter?: (value: O) => I;

  /**
   * 传出数据转换
   * @param value
   */
  outputFormatter?: (...args: any[]) => O;
};

export const WarpComponentWithFormatter: React.FC<WarpComponentWithFormatterProps> = props => {
  const {
    children,
    inputFormatter,
    outputFormatter,
    onChange,
    valuePropName = 'value',
    value,
    ...p
  } = props;
  if (!children) {
    return <></>;
  }
  const val = props[valuePropName];
  return React.cloneElement(children as any, {
    ...p,
    [valuePropName]: inputFormatter ? inputFormatter(val) : val,
    onChange(...args: any[]) {
      if (onChange) {
        outputFormatter ? onChange(outputFormatter(...args)) : onChange(...args);
      }
    },
  });
};
