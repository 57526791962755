import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import styled from 'styled-components';

import { ModelMenuItem } from '@pinweb/services';

export interface BreadcrumbProps {
  breadcrumbs?: ModelMenuItem[];
}

const Container = styled.div`
  background-color: #fff;
  padding: 16px ${props => props.theme.baseMargin};
  padding-bottom: 0;
`;

const RenderBreadcrumb: React.FC<BreadcrumbProps> = props => {
  const location = useLocation();

  // 记录面包屑路由带参
  const [breadCrumbMap, setBreadCrumbMap] = React.useState({});
  React.useEffect(() => {
    setBreadCrumbMap({
      ...JSON.parse(sessionStorage.breadCurmb || '{}'),
      ...breadCrumbMap,
      [location.pathname]: location,
    });
    sessionStorage.breadCurmb = JSON.stringify({
      ...JSON.parse(sessionStorage.breadCurmb || '{}'),
      ...breadCrumbMap,
      [location.pathname]: location,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Container className="basis-layout--breadcrumb">
      <Breadcrumb>
        {/* <Breadcrumb.Item> */}
        {/*  <Link to="/">首页</Link> */}
        {/* </Breadcrumb.Item> */}
        {(props.breadcrumbs || []).map(item => (
          <Breadcrumb.Item key={item.path!}>
            {item.path === location.pathname ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a href="#" onClick={e => e.preventDefault()} rel="noopener noreferrer">
                {item.readable_name}
              </a>
            ) : (
              <Link replace to={item.path! + (breadCrumbMap?.[item.path!]?.search || '')}>
                {item.readable_name}
              </Link>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </Container>
  );
};

export default RenderBreadcrumb;
