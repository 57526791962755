import React from 'react';
import { Form, Col, Row } from 'antd';
import { FormItemProps } from 'antd/es/form';

import { SchemaComponent } from '../core';
import { SchemaFormProps, SchemaFormRefObject } from './interface';
import { WarpComponentWithFormatter } from '../../ant-form/warp-components';

import {
  getDefaultFormItemPropsComponentProps,
  defaultLabelCol,
  defaultWrapperCol,
} from './service';

const SchemaForm: React.FC<SchemaFormProps<any>> = React.forwardRef<
  SchemaFormRefObject,
  SchemaFormProps<any>
>((props, ref) => {
  const [form] = Form.useForm();
  const { formItems = [], rowProps, ...formProps } = props;

  const renderFormItem = React.useMemo(() => {
    return (formItems || []).map((item, index) => {
      const { _children, name, inputFormatter, outputFormatter, colProps, ...formItemProps } = item;

      const { defaultFormItemProps, defaultComponentProps } = getDefaultFormItemPropsComponentProps(
        formProps,
        formItemProps,
        _children
      );

      return (
        <Form.Item
          key={index}
          name={name as FormItemProps['name']}
          {...defaultFormItemProps}
          {...formItemProps}
        >
          {inputFormatter || outputFormatter ? (
            <WarpComponentWithFormatter
              inputFormatter={inputFormatter}
              outputFormatter={outputFormatter}
            >
              <SchemaComponent {...defaultComponentProps} {..._children} />
            </WarpComponentWithFormatter>
          ) : (
            <SchemaComponent {...defaultComponentProps} {..._children} />
          )}
        </Form.Item>
      );
    });
  }, [formItems, formProps]);

  return (
    <Form
      ref={ref}
      form={form}
      wrapperCol={defaultWrapperCol}
      labelCol={defaultLabelCol}
      {...formProps}
    >
      {rowProps ? (
        <Row {...rowProps}>
          {renderFormItem.map((formItem, index) => (
            <Col key={index} {...formItems[index]?.colProps}>
              {formItem}
            </Col>
          ))}
        </Row>
      ) : (
        renderFormItem
      )}
    </Form>
  );
});

SchemaForm.displayName = 'Pinweb.SchemaForm';

export default SchemaForm;
