import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: ${props => props.theme.headerHeight};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  flex-shrink: 0;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding: 0 ${props => props.theme.baseMargin};
  position: relative;
  z-index: 9;
`;

export const HeaderContainerLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderContainerRight = styled.div`
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
`;

export const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

export const HeaderTriggerButton = styled.div`
  font-size: 20px;
  transition: all 0.3s, padding 0s;
  cursor: pointer;
  margin-right: ${props => props.theme.baseMargin};
`;

export const HeaderMenuItem = styled.div`
  line-height: ${props => props.theme.headerHeight};
  height: ${props => props.theme.headerHeight};
  cursor: pointer;
  margin-left: 20px;
  white-space: nowrap;
`;

export const UserAvatar = styled.img`
  height: calc(100% - ${props => props.theme.baseMargin});
  margin-right: 10px;
  border-radius: 50%;
`;
