import React from 'react';
import { Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import loadable from '@loadable/component';

import Uploader, { IUploaderProps } from '../uploader';

export interface IUploaderWithListProps extends IUploaderProps {
  isDrag?: boolean;
  needCrop?: boolean;
  cropperProps?: Cropper;
}

interface IUploaderWithListState {
  previewVisible: boolean;
  previewImage: string;
  uploadFileList: UploadFile[];
  images_list: UploadFile[];
  previewImg: { url: string; idx: number };
  isFirst: boolean;
  previewDragVisible: boolean;
  cropperVisible: boolean;
  cropperSrc: string;
}

const Cropper = loadable(
  () => import(/* webpackChunkName: "Cropper",webpackPrefetch: true */ 'react-cropper')
);

class UploaderWithList extends React.Component<IUploaderWithListProps> {
  static defaultProps: IUploaderWithListProps = {
    isDrag: false,
    needCrop: false,
    maxLen: 5,
  };

  state: IUploaderWithListState = {
    previewVisible: false,
    previewImage: '',
    uploadFileList: [],
    images_list: [],
    previewImg: { url: '', idx: 0 },
    isFirst: true,
    previewDragVisible: false,
    cropperVisible: false,
    cropperSrc: '',
  };

  deleteItem: UploadFile[] = [];
  cropper: Cropper | null = null;
  promiseFn: {
    resolve?: (
      value?: string | File | Blob | PromiseLike<string | File | Blob> | undefined
    ) => void;
    reject?: (value?: string | File | Blob | PromiseLike<string | File | Blob> | undefined) => void;
  } = {};

  uploadButton = () => (
    <div>
      <PlusOutlined />
      <div>上传</div>
    </div>
  );

  handlePreview = (file: UploadFile) => {
    const { url: previewImage } = file;
    this.setState({ previewImage, previewVisible: true });
  };

  handleClosePreview = () => {
    this.setState({ previewVisible: false, previewDragVisible: false });
  };

  isOverMaxLen = () => {
    const { value, maxLen } = this.props;
    const { uploadFileList } = this.state;

    let result;
    if (Array.isArray(value)) {
      result = maxLen && (uploadFileList.length >= maxLen || value.length >= maxLen);
    } else {
      result = (uploadFileList.map(item => item.status).includes('uploading') || value) && maxLen;
    }
    return result;
  };

  componentDidUpdate() {
    this.handleClearUploadFileList();
    this.handleUpdateImageList();
  }

  handleClearUploadFileList = () => {
    const { uploadFileList } = this.state;
    const status = uploadFileList.map(item => item.status);

    !status.includes('uploading') &&
      !status.includes('error') &&
      uploadFileList.length &&
      this.setState({ uploadFileList: [] });
  };

  handleUpdateImageList = () => {
    const { value, isDrag } = this.props;
    if (!isDrag) return;
    if (Array.isArray(value) && value.length > 0) {
      if (this.state.isFirst) {
        this.setState({
          images_list: value.map((item, idx) => ({
            uid: new Date().getTime() + '' + idx,
            url: item,
          })),
          isFirst: false,
        });
      }
    }
  };

  handleUploadFileListChange = (info: UploadChangeParam<UploadFile>) => {
    if (this.props.isDrag) {
      const { images_list } = this.state;
      info.fileList.forEach(item => {
        const index = this.deleteItem.findIndex((v: any) => v.url === item.url);
        if (index < 0) {
          const idx = this.state.images_list.findIndex((v: UploadFile) => v.url === item.url);
          if (idx < 0) {
            const imageList = [...images_list, item];
            this.setState({
              images_list: imageList,
            });
          } else {
            images_list.splice(idx, 1, item);
            this.setState({});
          }
          this.props.onFileChange && this.props.onFileChange(info);
        }
      });
    } else {
      this.setState({
        uploadFileList: info.fileList,
      });
      this.props.onFileChange && this.props.onFileChange(info);
    }
  };

  applyDrag = (
    arr: any,
    dragResult: {
      removedIndex: number | null;
      addedIndex: number | null;
      payload: any;
    }
  ) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }

    this.props.onChange && this.props.onChange(result.map((v: UploadFile) => v.url!));

    return result;
  };

  deleteImage = (idx: number) => {
    const deleteItem = this.state.images_list.splice(idx, 1);
    this.deleteItem.push(...deleteItem);
    this.setState({});
    this.props.onChange &&
      this.props.onChange(this.state.images_list.map((v: UploadFile) => v.url!));
  };

  render() {
    const { children, value, isDrag, maxLen, needCrop, cropperProps, ...options } = this.props;
    const { previewImage, previewVisible, cropperVisible, cropperSrc } = this.state;

    return (
      <>
        <Uploader
          value={value}
          accept="image/*"
          incorrectFileTypeTip="请上传正确格式的图片"
          listType="picture-card"
          {...options}
          onPreview={this.handlePreview}
          previewFile={file => {
            return new Promise<string>((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = function () {
                resolve(reader.result as string);
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            });
          }}
          transformFile={file => {
            if (needCrop) {
              return new Promise<any>((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  const cropperSrc = reader.result;
                  if (cropperSrc) {
                    this.setState({
                      cropperVisible: true,
                      cropperSrc,
                    });
                  }
                };
                this.promiseFn.resolve = resolve;
                this.promiseFn.reject = reject;
              });
            } else return file;
          }}
          onFileChange={this.handleUploadFileListChange}
        >
          {children || (this.isOverMaxLen() ? null : this.uploadButton())}
        </Uploader>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleClosePreview}>
          <img alt="" style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <Modal
          visible={needCrop ? cropperVisible : false}
          title="裁剪图片"
          okText="保存"
          onOk={() => {
            this.cropper!.getCroppedCanvas().toBlob((res: any) => {
              if (res) {
                this.promiseFn.resolve && this.promiseFn.resolve(res);
              } else {
                this.promiseFn.reject && this.promiseFn.reject();
              }
            });
            this.setState({
              cropperVisible: false,
            });
          }}
          onCancel={() => {
            this.setState({ cropperVisible: false });
          }}
          width={800}
        >
          <Cropper
            src={cropperSrc}
            className="company-logo-cropper"
            ref={cropper => {
              this.cropper = cropper;
            }}
            style={{ width: 600, height: 500 }}
            minCropBoxHeight={300}
            minCropBoxWidth={300}
            aspectRatio={1}
            guides={false}
            center={false}
            viewMode={3}
            cropBoxResizable={false}
            {...cropperProps}
          />
        </Modal>
      </>
    );
  }
}

export default UploaderWithList;
