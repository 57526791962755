import React from 'react';
import loadable from '@loadable/component';
import {
  Input,
  Checkbox,
  InputNumber,
  TreeSelect,
  DatePicker,
  Select,
  Radio,
  Cascader,
  ConfigProvider,
  Switch,
} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProviderProps } from 'antd/lib/config-provider';

import { Provider, create } from '../store';
import { SchemaTableProps } from '../table/interface';

export interface SchemaProviderProps extends ConfigProviderProps {
  schemaConfig?: {
    tableConfig?: Partial<SchemaTableProps<any>>;
  };
}

export interface SchemaProviderState {
  $components: Record<string, React.ComponentType<any>>;
  schemaConfig?: SchemaProviderProps['schemaConfig'];
}

const SchemaTable = loadable(
  () => import(/* webpackChunkName: "SchemaTable",webpackPrefetch: true */ '../table')
);

const SchemaForm = loadable(
  () => import(/* webpackChunkName: "SchemaForm",webpackPrefetch: true */ '../form')
);

const SchemaButtonGroup = loadable(
  () => import(/* webpackChunkName: "SchemaButtonGroup",webpackPrefetch: true */ '../button-group')
);

const SchemaModal = loadable(
  () => import(/* webpackChunkName: "SchemaModal",webpackPrefetch: true */ '../modal')
);

const SchemaRadioGroup = loadable(
  () => import(/* webpackChunkName: "SchemaRadioGroup",webpackPrefetch: true */ '../radio-group')
);

const RichtextEditor = loadable(
  () =>
    import(/* webpackChunkName: "RichtextEditor",webpackPrefetch: true */ '../../richtext-editor')
);

const Uploader = loadable(
  () => import(/* webpackChunkName: "Uploader",webpackPrefetch: true */ '../../uploader')
);

const UploaderPicWall = loadable(
  () =>
    import(
      /* webpackChunkName: "UploaderPicWall",webpackPrefetch: true */ '../../uploader-pic-wall'
    )
);

const AntAreaCascader = loadable(
  () =>
    import(
      /* webpackChunkName: "AntAreaCascader",webpackPrefetch: true */ '../../ant-area-cascader'
    )
);

const ZImage = loadable(
  () => import(/* webpackChunkName: "ZImage",webpackPrefetch: true */ 'react-zmage')
);

const { RangePicker } = DatePicker;

const { TextArea } = Input;

export const SchemaState = create<SchemaProviderState>({
  $components: {
    'schema-table': SchemaTable,
    'schema-form': SchemaForm,
    'richtext-editor': RichtextEditor,
    'z-image': ZImage,
    'ant-input': Input,
    'ant-password': Input.Password,
    'ant-text-area': TextArea,
    'ant-checkbox': Checkbox,
    'ant-checkbox-group': Checkbox.Group,
    'ant-input-number': InputNumber,
    'ant-tree-select': TreeSelect,
    'ant-modal': SchemaModal,
    'ant-button-group': SchemaButtonGroup,
    'ant-date-picker': DatePicker,
    'ant-select': Select,
    'ant-radio': Radio,
    'ant-radio-group': SchemaRadioGroup,
    'ant-range-picker': RangePicker,
    'ant-uploader': Uploader,
    'ant-uploader-pic-wall': UploaderPicWall,
    'ant-cascader': Cascader as any,
    'ant-area-cascader': AntAreaCascader as any,
    'ant-switch': Switch,
  },
});

export const SchemaProvider: React.FC<SchemaProviderProps> = props => {
  const { children, schemaConfig, ...antProps } = props;
  React.useEffect(() => {
    SchemaState.setState({ schemaConfig });
  }, [schemaConfig]);
  return (
    <ConfigProvider locale={zhCN} {...antProps}>
      <Provider value={SchemaState}>{children}</Provider>
    </ConfigProvider>
  );
};
