import { onReactionError, configure } from 'mobx';
import { persistStore } from '@pinweb/mobx-logic';

import userStore, { UserStore } from '@/stores/user';

configure({ enforceActions: 'observed' });

export enum StoreName {
  USERSTORE = 'userStore',
}

export type IUserStoreProp = {
  [StoreName.USERSTORE]: UserStore;
};

export default {
  [StoreName.USERSTORE]: userStore,
};

export const persistUserStore = persistStore<UserStore>(StoreName.USERSTORE, userStore);

onReactionError((err, derivation) => {
  console.error(derivation, err);
});
