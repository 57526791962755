import { hasWindow } from '@pinweb/utils';

import {
  StorageServices,
  Storage_ModelConfig_StorageType,
  Storage_ModelResource_State as UploadResultState,
} from '@pinweb/proto-api-client';

const { CosAuth } = require('@pinweb/libs/tx_storage');
const { Base64, AliCrypto } = require('@pinweb/libs/ali_storage');

/**
 * 七牛云 oss上传参数
 */
type QiniuReq = {
  token: string;
  key: string;
  domain: string;
};

/**
 * 腾讯云上传参数
 */
type TencentCloudReq = {
  key: string;
  Signature: string;
  'x-cos-security-token': string;
  'Content-Type': string;
};

/**
 * 阿里云上传参数
 */
type AliReq = {
  name: string;
  key: string;
  policy: string;
  OSSAccessKeyId: string;
  success_action_status?: '200' | '204';
  signature: string;
  'x-oss-security-token': string;
};

type UploadParams = QiniuReq | TencentCloudReq | AliReq | null;

function safeProtocol(url: string) {
  url = url.replace(/http:|https:/g, hasWindow() ? window.location.protocol : 'https:');
  if (url.indexOf('http') !== 0) {
    url = window.location.protocol + '//' + url;
  }
  return url;
}

/**
 * 获取当前oss上传地址和参数
 * @param preUploadReq
 */
async function getUploadUrlAndParams(
  preUploadReq: storage.UploadResourceReq,
  options?: { [key: string]: any }
) {
  const resp = await StorageServices.UploadResource(preUploadReq, options);
  const { storage_type, qn_token, tc_token, ali_token, resource } = resp;
  let uploadUrl = '';
  let uploadParams: UploadParams = null;

  switch (storage_type!) {
    case Storage_ModelConfig_StorageType.StorageTypeQiniu:
      uploadUrl = safeProtocol(qn_token!.upload_url!);
      uploadParams = {
        token: qn_token!.token,
        key: resource!.path!,
        domain: resource!.domain!,
      } as QiniuReq;
      break;
    case Storage_ModelConfig_StorageType.StorageTypeTCCloud:
      uploadUrl = safeProtocol(tc_token!.upload_url!);
      uploadParams = {
        Signature: CosAuth({
          SecretId: tc_token!.secret_id!,
          SecretKey: tc_token!.secret_key!,
          Method: 'POST',
          Pathname: '/',
        }),
        key: resource!.path!,
        'x-cos-security-token': tc_token!.token!,
        'Content-Type': resource!.content_type!,
      } as TencentCloudReq;
      break;
    case Storage_ModelConfig_StorageType.StorageTypeAliyun:
      uploadUrl = safeProtocol(ali_token!.upload_url!);

      // 1天上传有效期
      // eslint-disable-next-line no-var
      var policyText = {
        expiration:
          new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().replace(/\..+/, '') +
          'Z', // 设置Policy的失效时间，如果超过失效时间，就无法通过此Policy上传文件 格式ISO8601 GMT
        conditions: [
          ['content-length-range', 0, 1048576000], // 设置上传文件的大小限制，如果超过限制，文件上传到OSS会报错
        ],
      };
      // eslint-disable-next-line no-var
      var policy = Base64.encode(JSON.stringify(policyText));

      // eslint-disable-next-line no-var
      var bytes = AliCrypto.HMAC(
        // @ts-ignore
        AliCrypto.SHA1,
        policy,
        ali_token!.access_key_secret,
        {
          asBytes: true,
        }
      );

      // eslint-disable-next-line no-var
      var signature = AliCrypto.util.bytesToBase64(bytes);

      uploadParams = {
        key: resource!.path,
        policy,
        OSSAccessKeyId: ali_token!.access_key_id,
        signature,
        success_action_status: '200',
        'x-oss-security-token': ali_token!.token,
      } as AliReq;
      break;
    default:
      break;
  }

  return {
    uploadUrl,
    uploadParams,
    resp,
  };
}

/**
 * 通过getUploadUrlAndParams返回的id调用次方法，获取上传完成的地址
 * @param req
 */
async function getUploadResult(req: storage.UploadResourceCallBackReq) {
  return StorageServices.UploadResourceCallBack(req);
}

export { getUploadUrlAndParams, UploadParams, UploadResultState, getUploadResult };
