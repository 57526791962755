import { RouterProps } from './types';

const prefixRouterPath = (arr: RouterProps[], prefix = '/') =>
  arr.map(r => {
    const item = { ...r };
    const replace = (str: string, p: string): string => {
      if (str.indexOf(p) === 0) {
        str = str.substr(1);
      }
      return str.indexOf(p) === 0 ? replace(str, p) : str;
    };
    const func = (path: string) =>
      `/${[prefix, path]
        .map(v => replace(v, '/'))
        .filter(String)
        .join('/')}`;
    item.path = func(String(item.path));
    if (item.children) {
      item.children = prefixRouterPath(item.children, item.path);
    }
    return item;
  });

function getFlatRoutes(routes: RouterProps[]) {
  return routes.reduce((acc: Array<RouterProps & { name: string }>, cur: RouterProps) => {
    const item = { ...cur, name: cur.path as string };
    acc.push(item);
    if (Object.prototype.hasOwnProperty.call(item, 'children')) {
      const children = getFlatRoutes(item.children!);
      acc.push(...children);
    }
    return acc;
  }, []);
}

function trimPathName(path: string = '') {
  return path.split('/:')[0];
}

function getRoutes(routes: RouterProps[], path: string, location: any) {
  const allRoutes = prefixRouterPath(routes);
  const flatRoutes = getFlatRoutes(allRoutes);
  const $routes = flatRoutes.filter(r => r.name === path && r.children).pop()?.children;
  const $route =
    flatRoutes
      .filter(r => r.component)
      .find(r => {
        if (r.name.includes('/:')) {
          return location.pathname.includes(trimPathName(r.name));
        } else {
          return location.pathname === trimPathName(r.name);
        }
      }) || ({} as RouterProps);

  return {
    $routes,
    $route,
  };
}

export { getRoutes, prefixRouterPath, getFlatRoutes, trimPathName };
