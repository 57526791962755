import { EventTarget } from 'event-target-shim';

export class AbortError extends Error {
  name = 'AbortError';
  message = 'Aborted';
}

export class AbortSignal extends EventTarget {
  aborted = false;
}

export class AbortController {
  signal = new AbortSignal();

  abort = () => {
    const abortEvent = new CustomEvent('abort');

    this.signal.dispatchEvent(abortEvent);
    this.signal.aborted = true;
  };
}
