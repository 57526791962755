import React, { ErrorInfo } from 'react';
import styled from 'styled-components';
import { logger } from '@pinweb/logger';
import { Button } from 'antd';
import ErrorImg from './img/error.svg';
import styleConsts from '@/styles/vars/index.scss';

interface IErrorBoundaryState {
  error?: Error;
  errorInfo?: ErrorInfo;
}

interface IErrorBoundaryProps {}

const ErrorBox = styled.div`
  white-space: pre-wrap;
  text-align: center;
  padding: 20px 0;
`;

const Tips = styled.p`
  color: ${styleConsts.text3};
`;

const RefreshButton = styled(Button)`
  border-radius: 50px;
`;

class ErrorBoundary extends React.Component<IErrorBoundaryProps> {
  state: IErrorBoundaryState = {};

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
    // report
    logger.captureException(error);
  }

  getPreloadImgAttr(url: string) {
    const oImg = new Image();
    oImg.src = url;
  }

  componentDidMount() {
    // 预加载图片
    this.getPreloadImgAttr(ErrorImg);
  }

  handleRefresh = () => {
    this.setState({
      error: null,
      errorInfo: null,
    });
  };

  render() {
    const { error, errorInfo } = this.state;
    if (error || errorInfo) {
      return (
        <ErrorBox>
          {/* <div>{error?.toString()}</div>
          <div>{errorInfo?.componentStack}</div> */}
          <img src={ErrorImg} alt="" />
          <Tips>页面加载失败,请重新尝试</Tips>
          <p>
            <RefreshButton type="primary" onClick={this.handleRefresh}>
              Refresh
            </RefreshButton>
          </p>
        </ErrorBox>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
