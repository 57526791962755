import { HEADER_DEVICE_SID_KEY, HEADER_VERSION_KEY } from '@pinweb/services';
import { loggerClientBrowser } from '@pinweb/logger';
import { env } from '@pinweb/runtime';
import { uuid } from '@pinweb/utils';

import { isProductionMode } from './_';
import { handlerBrowserDeviceID } from './handler/deviceSID';
import { PinRegisterOptions } from './interface';

function handleRouteChange() {
  try {
    /**
     * add nonce id
     */
    env.nonceId = uuid();
    window?.addEventListener('popstate', () => {
      env.nonceId = uuid();
    });
  } catch (e) {
    console.log('pin register handleRouteChange', e);
  }
}

export default function register(options?: PinRegisterOptions) {
  try {
    const { PKGName, mode, version } = process.env;
    env.deviceSid = handlerBrowserDeviceID();

    loggerClientBrowser.mount({
      logLevel: isProductionMode('ERROR', 'DEBUG'),
      catType: isProductionMode('term_report', 'term_debug'),
      autoUpload: true,
      processRequestOptions(res) {
        res.headers!.set(HEADER_VERSION_KEY, `${PKGName}-${mode}-${version}`);
        res.headers!.set(HEADER_DEVICE_SID_KEY, env.deviceSid!);
        return res;
      },
      processRequestData(res) {
        return res.map(v => {
          v[HEADER_DEVICE_SID_KEY] = env.deviceSid;
          return v;
        });
      },
      ...options?.loggerOptions,
    });
    handleRouteChange();
  } catch (e) {
    console.log('pin register error', e);
  }
}
