import styled from 'styled-components';

export const PageWrap = styled.div`
  position: relative;
  // display: flex;
  // flex-direction: column;
  // height: 100%;
`;

export const PageHeader = styled.div`
  background-color: #fff;
  padding: 0 ${props => props.theme.basePadding};
`;

export const PageContainer = styled.div`
  margin: ${props => props.theme.baseMargin};
  padding: ${props => props.theme.basePadding};
  background-color: #fff;
  // flex: 1;
`;

export const PageFooter = styled.div`
  z-index: 10;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px -1px 0px 0px rgba(237, 237, 237, 0.5);
`;
