import React from 'react';
import { shallowEqual } from '@planjs/utils';
import hoistStatics from 'hoist-non-react-statics';

import { StoreContext } from './provider';
import {
  Store,
  MapStateToProps,
  DefaultRootState,
  Options,
  ConnectedState,
  ConnectProps,
} from './types';

function getDisplayName(WrappedComponent: React.ComponentType<any>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const defaultMapStateToProps = () => ({});

export function connect<TStateProps = {}, TOwnProps = {}, State = DefaultRootState>(
  mapStateToProps?: MapStateToProps<TStateProps, TOwnProps, State>,
  options: Options = {}
) {
  const shouldSubscribe = !!mapStateToProps;
  const finalMapStateToProps = mapStateToProps || defaultMapStateToProps;

  return function wrapWithConnect(
    WrappedComponent: React.ComponentType<TOwnProps & ConnectProps & TStateProps>
  ): React.ComponentType<TOwnProps & ConnectProps> {
    class Connect extends React.Component<
      TOwnProps & ConnectProps,
      ConnectedState<{}, Store<State>, {}>,
      Store
    > {
      static displayName = `Connect(${getDisplayName(WrappedComponent)})`;

      static contextType = StoreContext;

      static getDerivedStateFromProps(
        props: TOwnProps,
        prevState: ConnectedState<{}, Store<State>, {}>
      ) {
        // using ownProps
        if (mapStateToProps && mapStateToProps.length === 2 && props !== prevState.props) {
          return {
            subscribed: finalMapStateToProps(prevState.store.getState(), props),
            props,
          };
        }
        return { props };
      }

      store: Store<State>;
      unsubscribe: (() => void) | null = null;

      constructor(props: TOwnProps & ConnectProps, context: Store<State>) {
        super(props, context);

        this.store = this.context!;

        this.state = {
          subscribed: finalMapStateToProps(this.store.getState(), props),
          store: this.store,
          props,
        };
      }

      componentDidMount() {
        this.trySubscribe();
      }

      componentWillUnmount() {
        this.tryUnsubscribe();
      }

      shouldComponentUpdate(nextProps: ConnectProps, nextState: ConnectedState<any, any, any>) {
        return (
          !shallowEqual(this.props, nextProps) ||
          !shallowEqual(this.state.subscribed, nextState.subscribed)
        );
      }

      handleChange = () => {
        if (!this.unsubscribe) {
          return;
        }
        const nextState = finalMapStateToProps(this.store.getState(), this.props);
        this.setState({ subscribed: nextState });
      };

      trySubscribe() {
        if (shouldSubscribe) {
          this.unsubscribe = this.store.subscribe(this.handleChange);
          this.handleChange();
        }
      }

      tryUnsubscribe() {
        if (this.unsubscribe) {
          this.unsubscribe();
          this.unsubscribe = null;
        }
      }

      render() {
        const { storeForwardedRef, ...params } = this.props;
        const props: any = {
          ...params,
          ...this.state.subscribed,
          store: this.store,
        };

        return <WrappedComponent {...props} ref={storeForwardedRef} />;
      }
    }

    if (options.forwardRef) {
      const forwarded = React.forwardRef<any, TOwnProps>((props, ref) => {
        return <Connect {...props} storeForwardedRef={ref as any} />;
      });
      return hoistStatics(forwarded as any, WrappedComponent);
    }
    return hoistStatics(Connect, WrappedComponent);
  };
}
