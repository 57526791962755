export const isBrowser = typeof document !== 'undefined' && !!document.scripts;

declare interface PinEnv extends NodeJS.ProcessEnv {
  deviceSid?: string;
  /**
   * 防止重放，这里当页面路由切换就要更新
   */
  nonceId?: string;
}

declare global {
  // eslint-disable-next-line no-var
  var env: PinEnv;
}

export const env: PinEnv = {
  ...(() => {
    try {
      return process?.env;
    } catch (e) {
      return {} as PinEnv;
    }
  })(),
};

function getGlobal() {
  try {
    return global;
  } catch (e) {}
  try {
    return globalThis;
  } catch (e) {}
  return {};
}

try {
  // @ts-ignore global 可能是dom 或者 node 类型
  getGlobal().env = env;
} catch (e) {
  console.log('mount env error', e);
}
