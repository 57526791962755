import { cloneDeep } from 'lodash';
import { Moment } from 'moment/moment.d';

type OmitPaginate<S> = Omit<S, 'paginate'>;

type FetchResp<S> = {
  page: number;
  total: number;
  hasMore: boolean;
  loading: boolean;
  limit: number;
  resp: OmitPaginate<S> | undefined;
};

type OptionValue = string | number | string[] | number[] | boolean | Moment | Moment[];
type OptionsMap = Record<string, OptionValue>;

class FetchList<
  R extends { list_option?: core.ListOption },
  S extends { paginate?: core.Paginate },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  O = any
> {
  /**
   * 请求函数
   * @param r  请求参数
   * @return Promise<S>
   */
  private fetchFn: (r: R, o?: O) => Promise<S> = (r: R) => {
    console.log(r);
    return Promise.reject(new Error('please register fetchFn'));
  };

  /**
   * 校验option
   * @param option
   */
  static validValue = (option: core.ListOption_Option): core.ListOption_Option | undefined => {
    if (
      !option ||
      option.value === '' ||
      option.value === undefined ||
      option.value === null ||
      option.type === null ||
      option.type === undefined
    ) {
      return;
    }
    return option;
  };

  /**
   * 通知当前列表状态
   * @param result 结果
   */
  private toViewFn?: (result: FetchResp<S>) => void;

  req?: R;

  /**
   * 当前一页大小
   */
  limit = 10;

  /**
   * 当前页数
   */
  page = 1;

  /**
   * 总数
   * @note 现在后端只有第一页的时候返回total，fetchList会存total
   *       按照偏移量可能会存在漏数据
   */
  total = 0;

  /**
   * 有没有下一页， 第一次默认true 如果为false 再继续请求会报错
   */
  hasMore = true;

  /**
   * 有触发请求操作的时候会变成true，成功变成false
   */
  loading = false;

  /**
   * API services 请求参数 @pinweb/services
   */
  protected opt?: O;

  /**
   * 本次请求结果
   */
  protected resp: OmitPaginate<S> | undefined = undefined;

  /**
   * 缓存每页结果
   * @note 移动端列表加载是累加，按照page缓存一下结果
   */
  private cacheResp: {
    [page: string]: OmitPaginate<S>;
  } = {};

  /**
   * 是否开启缓存
   */
  private _isCache = false;

  /**
   * 失败回调函数
   * @private
   */
  private _onErrorCallbacks: Array<(err: any) => void> = [];

  /**
   * 服务端返回，下一页从此处开始加载
   */
  protected offset_token: string | undefined;

  constructor(
    fn?: (r: R, o?: O) => Promise<S>,
    req: Partial<R> = {},
    toViewFn?: (result: FetchResp<S>) => void,
    opt?: O,
    limit = 10
  ) {
    if (fn) {
      this.fetchFn = fn!;
    }
    this.limit = limit;
    this.req = (req as R) || {};
    this.toViewFn = toViewFn;
    this.opt = opt;
  }

  /**
   * 增加失败监听
   * @param cb
   */
  onError = (cb: (err?: any) => void) => {
    cb && this._onErrorCallbacks.push(cb);
  };

  /**
   * 取消失败回调
   * @param cb
   */
  offOnError = (cb: (err: any) => void) => {
    const index = this._onErrorCallbacks.findIndex(v => v === cb);
    if (~index) {
      this._onErrorCallbacks.splice(index, 1);
    }
  };

  private _callErrorCallback = (err: any) => {
    try {
      this._onErrorCallbacks.map(v => v(err));
    } catch (e) {
      console.log('fetchlist onerror error', e);
    }
  };

  get isCache() {
    return this._isCache;
  }

  /**
   * 设置是否开启缓存
   * 缓存
   * @param isCache
   */
  setIsCache = (isCache: boolean): this => {
    this._isCache = isCache;
    return this;
  };

  setFetchListFn = (fn: (r: R, o?: O) => Promise<S>): this => {
    this.fetchFn = fn;
    return this;
  };

  setToViewFn = (fn: (result: FetchResp<S>) => void): this => {
    this.toViewFn = fn;
    return this;
  };

  setReq = <Req extends R>(req: Req): this => {
    // TODO 深层merge
    this.req = Object.assign({}, this.req, req);
    return this;
  };

  setOpt = <Opt extends O>(opt: Opt): this => {
    // TODO 深层copy
    this.opt = Object.assign({}, this.opt, opt);
    return this;
  };

  setLimit = (limit: number): this => {
    if (limit >= 0) {
      this.limit = limit;
    }
    return this;
  };

  private emitState = (): FetchResp<S> => {
    const { page, total, hasMore, loading, resp, limit } = this;
    const result = {
      page,
      total,
      hasMore,
      loading,
      resp,
      limit,
    };
    this.toViewFn && this.toViewFn(result);
    return result;
  };

  /**
   * 自动合并cache的结果按照page的顺序
   * @note 合并数组，对象，忽略分页loading等结果
   *       必须先缓存第一页数据
   */
  get autoMergeCacheResult(): OmitPaginate<S> | undefined {
    if (this.cacheResp[1]) {
      return Object.keys(this.cacheResp).reduce<OmitPaginate<S>>((acc: any, page) => {
        if (page !== '1' && +page <= this.page) {
          const item: any = this.cacheResp[page];
          if (typeof item === 'object') {
            Object.keys(item).forEach(k => {
              if (item[k] instanceof Array) {
                acc[k].push(...item[k]);
              } else if (typeof item[k] === 'object' && acc[k]) {
                Object.assign(acc[k], item[k]);
              } else {
                acc[k] = item[k];
              }
            });
          }
        }
        return acc;
      }, cloneDeep(this.cacheResp[1]));
    }
    // throw new Error('first page is not cache');
  }

  /**
   * 获取当前缓存原始数据
   */
  get cacheResult() {
    return this.cacheResp;
  }

  /**
   * 获取当前options
   */
  get options(): core.ListOption_Option[] {
    return this.req?.list_option?.options || [];
  }

  /**
   * 当前总页数
   */
  get totalPage() {
    if (!this.limit) return 0;
    return Math.ceil(this.total / this.limit);
  }

  /**
   * 设置list_options, 当前没有校验参数，传过来是什么就是什么
   * @param options
   */
  setOptions = (options: core.ListOption_Option[]): this => {
    const { ...opt } = this.req!.list_option! || {};
    this.req!.list_option = { ...opt, options };
    return this;
  };

  /**
   * 删除 list_options options
   * @param types
   */
  deleteOption = (...types: number[]): this => {
    const { options = [], ...opt } = this.req!.list_option! || {};
    this.req!.list_option = {
      ...opt,
      options: options.filter((v: core.ListOption_Option) => !types.includes(v.type!)),
    };
    return this;
  };

  /**
   * 删除不等于type的条件
   * @param types
   */
  deleteOptionNotIn = (...types: number[]): this => {
    const { options = [], ...opt } = this.req!.list_option! || {};
    this.req!.list_option = {
      ...opt,
      options: options.filter((v: core.ListOption_Option) => types.includes(v.type!)),
    };
    return this;
  };

  /**
   * 增加 list_options options
   * @param opts
   */
  appendOption = (...opts: core.ListOption_Option[]): this => {
    if (!this.req?.list_option) {
      this.req!.list_option = {};
    }
    this.req!.list_option!.options = [
      ...(this.req!.list_option!.options || []),
      ...opts.filter(FetchList.validValue),
    ];
    return this;
  };

  /**
   * 替换 list_options options, 不存在则删除
   * @param options
   */
  replaceOption = (...options: core.ListOption_Option[]): this => {
    options.forEach(option => {
      this.deleteOption(option.type!);
      if (FetchList.validValue(option)) {
        this.appendOption(option);
      }
    });
    return this;
  };

  /**
   * 过滤掉list_options符合条件的值
   * @param values
   * @note 可以用于过滤0，大部分场景0可以作为前端的一个默认值
   */
  deleteOptionValue = (...values: Array<string | number>): this => {
    const { options = [], ...opt } = this.req!.list_option! || {};
    this.req!.list_option = {
      ...opt,
      options: options.filter((v: core.ListOption_Option) => !values.includes(v.value!)),
    };
    return this;
  };

  /**
   * 转换list_option成对象
   * @returns { [type: number]: value }
   * @memberof FetchList
   */
  optionsToMap = (): { [type: string]: string } => {
    const { options = [] } = this.req!.list_option! || {};
    return options.reduce((acc: { [type: string]: string }, item) => {
      acc[item.type!] = item.value!;
      return acc;
    }, {});
  };

  /**
   * 通过object替换并且添加option
   * @param map
   * @param fn
   */
  mapToOptions = (
    map: OptionsMap,
    fn?: (key: string, value: OptionValue) => core.ListOption_Option
  ): this => {
    this.setOptions(FetchList.forEachMapGetOptions(map, fn));
    return this!;
  };

  /**
   * 通过map转换成list_option存在则替换
   */
  replaceMapToOptions = (
    map: OptionsMap,
    fn?: (key: string, value: OptionValue) => core.ListOption_Option
  ): this => {
    this.deleteOption(...Object.keys(map).map(Number)).replaceOption(
      ...FetchList.forEachMapGetOptions(map, fn)
    );
    return this;
  };

  /**
   * 对象转换成list_options
   * @param map
   * @param fn
   */
  static forEachMapGetOptions(
    map: OptionsMap,
    fn?: (key: string, value: OptionValue) => core.ListOption_Option | void
  ): core.ListOption_Option[] {
    // 一些时间相关的，值用moment类型，可以直接转换成后端需要的 秒级时间戳
    function processMomentDate(value: OptionValue) {
      // TODO CHECK 这里应该 instanceof 判断原型链, 现在没有伪造的情况
      function isMoment(v: any) {
        return typeof v === 'object' && v !== null && (v as any)._isAMomentObject;
      }
      try {
        if (isMoment(value)) {
          return (value as Moment).format('X');
        }
        if (value instanceof Array) {
          return (value as any[]).map(v => (isMoment(v) ? (v as Moment).format('X') : v));
        }
      } catch (e) {}
      return value;
    }

    return Object.keys(map)
      .reduce((options: core.ListOption_Option[], k) => {
        let option: core.ListOption_Option = {
          type: +k,
          // 去掉不正常的值
          value:
            map[k] !== '' &&
            map[k] !== undefined &&
            map[k] !== null &&
            (typeof map[k] === 'number' ? !isNaN(map[k] as number) : 1) &&
            map[k] !== 'undefined' &&
            map[k] !== 'null' &&
            map[k] !== 'NaN'
              ? String(processMomentDate(map[k])).trim()
              : '',
        };
        if (fn) {
          option = fn(k, map[k]) as core.ListOption_Option;
        }
        if (FetchList.validValue(option)) {
          options.push(option);
        }
        return options;
      }, [])
      .sort((a, b) => a.type! - b.type!);
  }

  /**
   * 使用map 重置表单查询参数
   * @param map
   */
  resetOptionsMap = (map?: OptionsMap): this => {
    try {
      this.req!.list_option!.options = [];
    } catch (error) {}
    if (map) {
      this.mapToOptions(map);
    }
    return this;
  };

  private checkLoading = () => {
    if (this.loading) {
      return Promise.reject(new Error('GetList is loading, please await getList success'));
    } else {
      return Promise.resolve();
    }
  };

  /**
   * 请求前参数处理
   *  req
   * @param req
   * @param page
   */
  private processReq = (req: R = this.req!, page: number = this.page): R => {
    if (page < 1) {
      page = 1;
    }
    const isNextPage = page - this.page === 1;
    this.page = page;
    this.req = req;

    const { list_option, ...opt } = this.req;
    const { options = [], ...other } = list_option || {};
    return {
      ...opt,
      list_option: {
        ...other,
        // 排序一下 options 防止同样筛选条件不同位置
        options: options.filter(FetchList.validValue).sort((a, b) => a.type! - b.type!),
        limit: this.limit,
        offset: this.offset_token ? undefined : (page - 1) * this.limit,
        offset_token: isNextPage ? this.offset_token : undefined,
      },
    } as R;
  };

  /**
   * 处理请求后的参数
   * @param resp
   */
  private processResp = (resp: S): S => {
    const { paginate, ...result } = resp;
    if (this.page === 1 && paginate?.offset === 0) {
      this.total = paginate!.total!;
    }
    // limit 0 的时候可能会获取不完数据，后端现在限制 1000
    if (paginate?.limit) this.limit = paginate?.limit;
    if (!paginate) {
      // 当没有返回分页结构的时候，不分页
      this.hasMore = false;
    } else {
      this.hasMore =
        this.total > this.page * this.limit && this.limit <= this.total && !!this.limit;
    }
    this.resp = result!;
    this.offset_token = paginate?.next_offset_token;
    return resp;
  };

  /**
   * 请求所有数据
   * @param req
   * @param page default 当前页面，第一页为 1，从当前 page 往后获取数据
   * @param opt
   */
  getAllList = async (
    req?: R,
    page = this.page,
    opt?: O
  ): Promise<{
    result: Array<S>;
    cancel: () => void;
  }> => {
    const result: S[] = [];
    try {
      if (page === 1) {
        result.push(await this.getList(req, page, opt));
      }
      let count = this.totalPage - page;
      let isCancel = false;
      while (count > 0 && this.hasMore) {
        result.push(await this.nextList(req, opt));
        count--;
      }
      if (isCancel) return Promise.reject(new Error('Cancelled'));
      return {
        result,
        cancel() {
          count = 0;
          isCancel = true;
        },
      };
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /**
   * 请求列表
   * @param req
   * @param page
   * @param opt
   */
  getList = async (req?: Partial<R>, page?: number, opt?: O): Promise<S> => {
    if (opt) {
      this.opt = opt;
    }
    this.loading = true;
    const r = this.processReq(req as R, page);
    this.emitState();
    const _page = this.page;
    try {
      const resp = await this.fetchFn(r, this.opt);
      return this.processResp(resp);
    } catch (error) {
      this._callErrorCallback(error);
      return Promise.reject(error);
    } finally {
      if (this.isCache && this.resp) {
        if (_page === 1) {
          this.cacheResp = {};
        }
        this.cacheResp[_page] = this.resp;
      }
      this.loading = false;
      this.emitState();
      delete this.resp;
    }
  };

  /**
   * 刷新列表，如果不传page 会自动跳回第一页
   * @param req
   * @param page
   * @param o
   */
  refreshList = (req?: Partial<R>, page?: number, o?: O): Promise<S> => {
    if (!page) {
      page = 1;
    }
    this.hasMore = true;
    return this.getList(req, page, o);
  };

  /**
   * 改变列表limit长度
   * @param limit
   * @param req
   * @param o
   */
  changeLimitList = (limit: number, req?: R, o?: O): Promise<S> => {
    return this.changePageLimitList(this.page, limit, req, o);
  };

  /**
   * 改变页数
   * @param page
   * @param req
   * @param o
   */
  changePageList = (page: number, req?: R, o?: O): Promise<S> => {
    return this.changePageLimitList(page, this.limit, req, o);
  };

  /**
   * 使用 page pageSize 更新列表
   * @param page
   * @param limit
   * @param req
   * @param o
   */
  changePageLimitList = (page: number, limit: number, req?: R, o?: O): Promise<S> => {
    if (limit <= 0) {
      limit = 10;
      console.warn('limit must > 0');
    }
    if (page < 1) {
      page = 1;
      console.warn('page must > 1');
    }
    this.hasMore = true;
    this.page = page;
    this.limit = limit;
    return this.getList(req, this.page, o);
  };

  /**
   * 刷新当前页面
   * @param req
   * @param o
   */
  refreshCurPageList = (req?: R, o?: O) => {
    return this.getList(req, this.page, o);
  };

  /**
   * 下一页
   * @param [req]
   * @param [o]
   * @returns
   */
  nextList = async (req?: R, o?: O): Promise<S> => {
    await this.checkLoading();
    if (!this.hasMore) {
      return Promise.reject(new Error('No more data'));
    }
    try {
      return await this.getList(req, this.page + 1, o);
    } catch (e) {
      if (this.page > 1) {
        this.page--;
      }
      return Promise.reject(e);
    }
  };

  /**
   * 上一页
   * @param [req]
   * @param [o]
   * @returns
   * TODO 暂时貌似没有用，要debug下
   */
  prevList = async (req?: R, o?: O): Promise<S> => {
    try {
      await this.checkLoading();
      return await this.getList(req, this.page - 1, o);
    } catch (e) {
      this.page++;
      return Promise.reject(e);
    }
  };
}

export { FetchList, FetchResp, OptionValue, OptionsMap, OmitPaginate };
