import React from 'react';
import Router from '@/libs/router';
import routes from '@/routes';
import ErrorBoundary from '@/components/error-boundary';

class App extends React.Component {
  async componentDidMount() {
    console.info('initi url:', window.location.href);
  }

  render() {
    return (
      <ErrorBoundary>
        <div
          style={{
            height: '100vh',
          }}
        >
          <Router routes={routes} />
        </div>
      </ErrorBoundary>
    );
  }
}

export default App;
