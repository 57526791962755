import { BaseClient } from '../../core';
import { isPrimitive } from '../../utils';
import { IExportData, ILog } from '../../core/types/log';
import { ConnectionWX, Headers, Request, RequestMethod, RequestOptions } from '@pinweb/http';
import { exceptionHandler, handleErrorWithoutStack } from '../browser/exceptionHandler';

export class TTClient extends BaseClient {
  captureException(e: Error) {
    const exception = isPrimitive(e) ? handleErrorWithoutStack(e) : exceptionHandler(e);
    this.capture(exception);
  }

  captureMessage(data: ILog) {
    this.capture(data);
  }

  _doUpload(url: string, data: IExportData[]) {
    const xhr = new ConnectionWX();

    return xhr.createConnection(
      new Request(
        this._options.processRequestOptions!({
          url,
          method: RequestMethod.Post,
          body: JSON.stringify(this._options.processRequestData!(data)),
          headers: new Headers({
            'content-type': 'application/json',
          }),
        } as RequestOptions)
      )
    ).response;
  }
}
