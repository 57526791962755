import { Response } from '@pinweb/http';

import { isPropertyExist, timestampWithMs } from '../utils';
import { getCurrentHub } from './hub';
import { IClient } from './types/client';
import { IOptions, CatType } from './types/options';
import { Severity } from './types/severity';
import { IExportData, ILog } from './types/log';

export abstract class BaseClient implements IClient {
  protected _queue: IExportData[];
  protected _options: IOptions;

  constructor(options: IOptions) {
    this._queue = [];
    this._options = options;

    // 默认采样率 1 现在业务量不大
    if (!isPropertyExist(this._options, 'sampleRate')) {
      this._options.sampleRate = 1;
    }

    // 默认最大日志数
    if (!isPropertyExist(this._options, 'maxLogNum')) {
      this._options.maxLogNum = 10;
    }

    // 默认日志级别 warn
    if (!isPropertyExist(this._options, 'logLevel')) {
      this._options.logLevel = 'WARN';
    }

    // 默认env
    if (!isPropertyExist(this._options, 'environment')) {
      const env = process.env.NODE_ENV;
      env && (this._options.environment = env);
    }

    // 默认延时
    if (!isPropertyExist(this._options, 'uploadDelay')) {
      this._options.uploadDelay = 5;
    }

    // 默认上报地址
    if (!isPropertyExist(this._options, 'uploadServer')) {
      this._options.uploadServer = 'https://app.pinquest.cn/gateway/oss/Report';
      // TODO 环境变量(需要支持是否正式环境的判断) 非正式环境都上报到dev1
      if (['production', 'feishu', 'lark'].every(v => process.env.mode !== v)) {
        this._options.uploadServer = 'https://dev1.pinquest.cn/gateway/oss/Report';
      }
    }

    // 默认上传处理函数
    if (!isPropertyExist(this._options, 'processRequestOptions')) {
      this._options.processRequestOptions = res => res;
    }

    // 默认处理数据函数
    if (!isPropertyExist(this._options, 'processRequestData')) {
      this._options.processRequestData = res => res;
    }

    if (!isPropertyExist(this._options, 'catType')) {
      this._options.catType = 'term_report';
    }
  }

  getOptions() {
    return this._options;
  }

  capture(data: ILog) {
    // 处理采样
    if (Math.random() >= this._options.sampleRate!) {
      return;
    }

    // 处理日志级别
    if (Severity[this._options.logLevel!] > data.level) {
      return;
    }

    const scope = getCurrentHub().getScope();
    const breadcrumbs = scope.getBreadcrumbs();
    const ext = scope.getExt();
    const timestamp = timestampWithMs();

    this._queue.push({
      ...data,
      level: Severity[data.level] as keyof typeof Severity,
      breadcrumbs,
      timestamp,
      ext: Object.assign({}, ext, data.ext),
    });

    try {
      if (this._options.autoUpload) {
        getCurrentHub()
          .upload()
          .catch(err => err);
      } else if (this._options.maxLogNum! <= this._queue.length) {
        getCurrentHub()
          .upload()
          .catch(err => err);
      }
    } catch (e) {}
  }

  captureException(_e: Error) {
    throw new Error('Client has to implement `captureException` method');
  }

  captureMessage(_data: ILog) {
    throw new Error('Client has to implement `captureMessage` method');
  }

  _doUpload(_url: string, _data: IExportData[]): Promise<Response> {
    return Promise.reject(new Error('Client has to implement `doUpload` method'));
  }

  upload() {
    return new Promise<Response>((resolve, reject) => {
      setTimeout(() => {
        try {
          const exportParams: {
            environment?: string;
            release?: string;
          } = {
            // TODO check env
            environment: process.env.mode,
            release: `${process.env.PKGName}-${process.env.version}`,
          };

          if (this._queue.length) {
            // 上传前添加信息
            const logs = this._queue
              .map(item => {
                const log: IExportData = {
                  cat: this._options.catType,
                  ...exportParams,
                  ...item,
                };
                // TODO 要改改 报警用的
                // 现在等级为ERROR才会上报
                if (
                  this._options.catType === CatType[CatType.term_report] &&
                  !log.warningDesc &&
                  log.level === 'ERROR'
                ) {
                  log.warningDesc = `${exportParams.release}:[${item.name}] ${(
                    item.message || ''
                  ).slice(0, 100)}`;
                }
                return log;
              })
              .filter(v => !!v.name!);
            this.clearQueue();
            if (!logs.length) return;
            // TODO 采样过滤重复的错误
            this._doUpload(this._options.uploadServer!, logs).then(resolve, err => {
              this._queue.unshift(...logs);
              reject(err);
            });
          }
        } catch (e) {
          console.error('logger upload error', e);
          reject(e);
        }
      }, this._options.uploadDelay! * 1000);
    });
  }

  clearQueue() {
    this._queue = [];
  }
}
