import React from 'react';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import RenderRoutes from './components/RenderRoutes';
import { RouterProps } from './types';
import { prefixRouterPath } from './getRoutes';

interface RouteProps {
  mode?: 'hash' | 'history';
  routes?: RouterProps[];
}

function Router(props: RouteProps) {
  const { mode = 'hash', routes = [] } = props;
  const computedRoutes = prefixRouterPath(routes);
  console.warn('routes', computedRoutes);

  return mode === 'hash' ? (
    <HashRouter>
      <RenderRoutes routes={computedRoutes} />
    </HashRouter>
  ) : (
    <BrowserRouter>
      <RenderRoutes routes={computedRoutes} />
    </BrowserRouter>
  );
}

export default Router;
