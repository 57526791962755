import { Response } from './response';

export type InterceptorFulfilled<R> = (this: Interceptor<R>, r: R) => Promise<R> | R;
export type InterceptorRejected<R> = (
  this: Interceptor<R>,
  r: Error | Response | R
) => Promise<any> | any | Promise<R> | R;

export interface InterceptorHandler<R> {
  fulfilled: InterceptorFulfilled<R>;
  rejected: InterceptorRejected<R> | null;
}

export class Interceptor<R> {
  private handlers: (InterceptorHandler<R> | null)[] = [];

  /**
   * p  of interceptor snap lock promise
   */
  p: Promise<void> | null = null;
  private resolve: any = null;
  private reject: any = null;

  /**
   * All requests will be locked afterwards
   * @memberof Interceptor
   */
  lock() {
    if (!this.resolve) {
      // eslint-disable-next-line promise/param-names
      this.p = new Promise((_resovle, _reject) => {
        this.resolve = _resovle;
        this.reject = _reject;
      });
    }
  }

  /**
   * All requests after release
   * @memberof Interceptor
   */
  unLock() {
    if (this.resolve) {
      this.resolve();
      this.p = this.reject = this.resolve = null;
    }
  }

  /**
   * All requests after cancellation
   * @param {string} msg
   * @memberof Interceptor
   */
  clear(msg?: string) {
    if (this.reject) {
      this.reject(msg || 'cancel');
      this.p = this.reject = this.resolve = null;
    }
  }

  /**
   * Add a new interceptor
   * @param {InterceptorFulfilled} fulfilled
   * @param {InterceptorRejected} rejected
   * @returns {number} handler ID used to remove interceptor
   * @memberof Interceptor
   */
  use(fulfilled: InterceptorFulfilled<R>, rejected: InterceptorRejected<R> | null = null): number {
    this.handlers.push({ fulfilled, rejected });
    return this.handlers.length - 1;
  }

  /**
   * Remove a interceptor
   * @param {number} id
   * @memberof Interceptor
   */
  eject(id: number) {
    if (this.handlers[id]) {
      this.handlers[id] = null;
    }
  }

  /**
   * Iterate over all the registered interceptors
   * @param {(handler: InterceptorHandler) => void} fn
   * @memberof Interceptor
   */
  forEach(fn: (handler: InterceptorHandler<R>) => void) {
    this.handlers.filter(v => v).forEach(h => fn.call(this, h!));
  }
}
