import { Http, httpRequest } from './http';
import { RequestMethod } from './enums';
import { ConnectionAdapter } from './interfaces';
import { RequestOptions } from './request_options';
import { Interceptor, InterceptorFulfilled, InterceptorRejected } from './interceptor';
import {
  InterceptorRequestOptionsArgs,
  InterceptorResponse,
  InterceptorRequestOptions,
  InterceptorRequest,
} from './interceptor_interfaces';

function mergeOptions(
  defaultOpts: InterceptorRequestOptionsArgs,
  opts: InterceptorRequestOptionsArgs | undefined,
  method: RequestMethod,
  url: string
) {
  const newOptions = new InterceptorRequestOptions(defaultOpts);
  if (opts) {
    return newOptions.merge(
      new InterceptorRequestOptions({
        method: method,
        url: opts.url || url,
        search: opts.search,
        params: opts.params,
        headers: opts.headers,
        body: opts.body,
        withCredentials: opts.withCredentials,
        responseType: opts.responseType,
        waitLock: opts.waitLock,
        timeOut: opts.timeOut,
        signal: opts.signal,
        skipInterceptor: opts.skipInterceptor,
        extra: opts.extra,
      })
    );
  }

  return newOptions.merge(new InterceptorRequestOptions({ method, url }));
}

export class InterceptorHttp extends Http {
  protected defaultOptions: InterceptorRequestOptions;
  interceptor = {
    request: new Interceptor<InterceptorRequest>(),
    response: new Interceptor<InterceptorResponse>(),
  };

  constructor(opts?: InterceptorRequestOptions, adapter?: ConnectionAdapter) {
    super(undefined, adapter);

    this.defaultOptions = opts || new InterceptorRequestOptions();
  }

  request(url: string | InterceptorRequest, options?: InterceptorRequestOptionsArgs) {
    let opt: InterceptorRequest;
    if (typeof url === 'string') {
      opt = new InterceptorRequest(
        mergeOptions(
          this.defaultOptions,
          options,
          Object.prototype.hasOwnProperty.call(options, 'method')
            ? (options!.method! as RequestMethod)
            : Object.prototype.hasOwnProperty.call(this.defaultOptions, 'method')
            ? (this.defaultOptions.method as RequestMethod)
            : RequestMethod.Get,
          url
        )
      );
    } else if (url instanceof InterceptorRequest) {
      opt = url;
    } else {
      throw new Error('First argument must be a url string or Request instance.');
    }

    const warpWaitLockFN = <R>(
      interceptor: Interceptor<R>,
      fn: InterceptorFulfilled<R> | InterceptorRejected<R> | null
    ) => {
      if (fn) {
        return (arg1: R) => {
          const waitLock = opt?.waitLock ?? this.defaultOptions?.waitLock;
          return interceptor.p && waitLock !== false
            ? interceptor.p.then(() => fn.call(interceptor, arg1))
            : fn.call(interceptor, arg1);
        };
      }
    };
    const checkSkipInterceptor = (fn: () => void) => !opt.skipInterceptor && fn();

    let promise = Promise.resolve<any>(opt);
    const chain: (
      | InterceptorFulfilled<InterceptorRequest>
      | InterceptorFulfilled<InterceptorResponse>
      | InterceptorRejected<InterceptorRequest>
      | InterceptorRejected<InterceptorResponse>
      | null
    )[] = [];
    checkSkipInterceptor(() => {
      this.interceptor.request.forEach(({ fulfilled, rejected }) =>
        chain.push(
          warpWaitLockFN<InterceptorRequest>(this.interceptor.request, fulfilled)!,
          warpWaitLockFN<InterceptorRequest>(this.interceptor.request, rejected!)!
        )
      );
    });
    chain.push((r: InterceptorRequest) => httpRequest(this.adapter, r) as any, null);
    checkSkipInterceptor(() => {
      this.interceptor.response.forEach(({ fulfilled, rejected }) =>
        chain.push(
          warpWaitLockFN<InterceptorResponse>(this.interceptor.response, fulfilled)!,
          warpWaitLockFN<InterceptorResponse>(this.interceptor.response, rejected!)!
        )
      );
    });
    while (chain.length) {
      const fulfilled = chain.shift();
      const rejected = chain.shift() as InterceptorRejected<InterceptorResponse> | null;
      promise = promise.then(fulfilled as any, rejected);
    }
    return promise as Promise<InterceptorResponse>;
  }

  get(url: string, options?: InterceptorRequestOptionsArgs) {
    return this.request(
      new InterceptorRequest(mergeOptions(this.defaultOptions, options, RequestMethod.Get, url))
    );
  }

  post(url: string, body: any, options?: InterceptorRequestOptionsArgs) {
    return this.request(
      new InterceptorRequest(
        mergeOptions(
          this.defaultOptions.merge(new RequestOptions({ body: body })),
          options,
          RequestMethod.Post,
          url
        )
      )
    );
  }

  put(url: string, body: any, options?: InterceptorRequestOptionsArgs) {
    return this.request(
      new InterceptorRequest(
        mergeOptions(
          this.defaultOptions.merge(new RequestOptions({ body: body })),
          options,
          RequestMethod.Put,
          url
        )
      )
    );
  }

  delete(url: string, options?: InterceptorRequestOptionsArgs) {
    return this.request(
      new InterceptorRequest(mergeOptions(this.defaultOptions, options, RequestMethod.Delete, url))
    );
  }

  patch(url: string, body: any, options?: InterceptorRequestOptionsArgs) {
    return this.request(
      new InterceptorRequest(
        mergeOptions(
          this.defaultOptions.merge(new RequestOptions({ body: body })),
          options,
          RequestMethod.Patch,
          url
        )
      )
    );
  }

  head(url: string, options?: InterceptorRequestOptionsArgs) {
    return this.request(
      new InterceptorRequest(mergeOptions(this.defaultOptions, options, RequestMethod.Head, url))
    );
  }

  options(url: string, options?: InterceptorRequestOptionsArgs) {
    return this.request(
      new InterceptorRequest(mergeOptions(this.defaultOptions, options, RequestMethod.Options, url))
    );
  }
}
