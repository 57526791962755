import { htmlTreeAsString, timestampWithMs } from '../../../utils';
import { getCurrentHub } from '../../../core';

export function catchClickQueue() {
  if (window.addEventListener) {
    if ('ontouchstart' in document.documentElement) {
      window.addEventListener('touchstart', _storeClickedDom, !0);
    } else {
      window.addEventListener('click', _storeClickedDom, !0);
    }
  } else {
    // @ts-ignore
    document.attachEvent('onclick', _storeClickedDom);
  }
}

function _storeClickedDom(e: MouseEvent | TouchEvent) {
  const message = htmlTreeAsString(e.target || e);
  const timestamp = timestampWithMs();

  const currentHub = getCurrentHub();

  currentHub.addBreadcrumb({
    timestamp,
    message,
    category: 'ui.clickOrTouch',
  });
}
