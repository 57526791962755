import React from 'react';
import { useViewport } from './useViewport';

interface Props {
  slotPc: React.ReactElement;
  slotMobile: React.ReactElement;
}

const Viewport: React.FC<Props> = props => {
  const { width } = useViewport();
  const breakpointMobile = 750;

  return <>{width > breakpointMobile ? props.slotPc : props.slotMobile}</>;
};

export default React.memo(Viewport);
