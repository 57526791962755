import { Events } from '@pinweb/core';
import {
  CloseMsg,
  ConnectionAdapter,
  SocketClient,
  SocketOptions,
  SocketDataType,
} from './interfaces';
import { isPropertyExist, isRealObject } from './utils';
import { ReadyState } from './enums';
import { Response } from './response';

export class Socket {
  private readonly options: SocketOptions;
  private client: ConnectionAdapter;
  private task!: SocketClient | null;
  private events = new Events();

  private heartbeat: {
    ping: boolean;
    duration: number;
    data: ArrayBuffer | string;
    timerID: number;
  } = {
    ping: false,
    duration: 0,
    data: 'ping',
    timerID: 0,
  };

  constructor(client: ConnectionAdapter, options: SocketOptions) {
    if (!isPropertyExist<SocketOptions>(options, 'autoOpen')) {
      options.autoOpen = true;
    }
    this.options = options;
    this.client = client;

    // auto open socket
    options.autoOpen && this.open();
  }

  private _checkTask() {
    if (!this.task) {
      return Promise.reject(new Error('Please open socket'));
    }
  }

  private _clearPingTimer() {
    if (this.heartbeat.timerID) clearTimeout(this.heartbeat.timerID);
    this.heartbeat.timerID = 0;
  }

  // TODO 检查发包格式
  private _processRequestData = (data: SocketDataType): string | ArrayBuffer | null => {
    if (typeof data === 'string') {
      return data;
    } else if (data instanceof ArrayBuffer) {
      return data;
    } else if (isRealObject(data)) {
      return JSON.stringify(data);
    } else {
      return null;
    }
  };

  removeListener() {
    this.events.off();
  }

  open(): SocketClient {
    if (this.task) return this.task;
    return (this.task = this.client.createConnection(this.options, { events: this.events }));
  }

  /**
   * 保持之前注册的事件，重新打开一个链接
   * 如果需要移除之前的链接调用 removeListener
   */
  reOpen(): SocketClient {
    try {
      this.close();
      this.task = null;
    } catch (e) {}
    this.open();
    if (this.heartbeat.ping) {
      this.ping(this.heartbeat.duration, this.heartbeat.data);
    }
    return this.task!;
  }

  /**
   * socket 还没死的情况下隔短时间会发送一个ping的包，如果有消息发送也相当于一个ping（发消息同时不会发送ping）
   * @param duration
   * @param data
   */
  ping(duration: number, data?: string | ArrayBuffer | object) {
    this.heartbeat.duration = duration;
    this.heartbeat.ping = true;
    const ret = this._processRequestData(data!);
    if (ret) {
      this.heartbeat.data = ret;
    }
    this._clearPingTimer();
    this.heartbeat.timerID = setTimeout(
      () => this.send(this.heartbeat.data).catch(res => res),
      this.heartbeat.duration
    );
  }

  cancelPing() {
    this.heartbeat.ping = false;
    this._clearPingTimer();
  }

  async send(data: string | ArrayBuffer | object): Promise<Response> {
    const ret = this._processRequestData(data);
    await this._checkTask();
    if (this.task?.readyState !== ReadyState.OPEN) {
      return Promise.reject(
        new Error(`Socket client is ${ReadyState[this.task!.readyState]}.\nCan't send message`)
      );
    }
    if (this.heartbeat.ping) {
      this.ping(this.heartbeat.duration, this.heartbeat.data);
    }
    if (ret) {
      return this.task?.send(ret);
    }
    return Promise.reject(new Error('Data is not supported.'));
  }

  close(msg?: CloseMsg) {
    if (this.task?.readyState! in [ReadyState.CONNECTING, ReadyState.OPEN]) {
      this.task!.close(msg);
    }
    this._clearPingTimer();
    this.task = null;
  }

  onOpen(fn: (...res: any[]) => void) {
    this.events.on('onOpen', fn);
  }

  onMessage(fn: (res: Response) => void) {
    this.events.on('onMessage', fn);
  }

  onError(fn: (res: Response) => void) {
    this.events.on('onError', fn);
  }

  onClose(fn: (res: Response) => void) {
    this.events.on('onClose', fn);
  }

  get readyState() {
    return this.task?.readyState;
  }
}

const noop = function() {};
const w = typeof window === 'object' ? window : noop;
export const ArrayBuffer: ArrayBufferConstructor = (() => (w as any).ArrayBuffer || noop)();
