const createAbortError = () => {
  const error = new Error('Delay aborted');
  error.name = 'AbortError';
  return error;
};

export interface DelayProps {
  value?: any;
  signal?: AbortSignal;
}

export interface createDelayProps {
  clearTimeout?: (handle?: number | undefined) => void;
  setTimeout?: (handler: TimerHandler, timeout?: number | undefined, ...args: any[]) => number;
  willResolve?: boolean;
}

const createDelay = (props: createDelayProps) => (ms: number, delayProps: DelayProps = {}) => {
  const { value, signal } = delayProps;
  const { clearTimeout: defaultClear, setTimeout: set, willResolve } = props;

  if (signal?.aborted) {
    return Promise.reject(createAbortError());
  }

  let timeoutId: number | null;
  let settle: () => void;
  let rejectFn: (reason?: any) => void;
  const clear = defaultClear || clearTimeout;

  const signalListener = () => {
    timeoutId !== null && clear(timeoutId);
    rejectFn(createAbortError());
  };

  const cleanup = () => {
    if (signal) {
      signal.removeEventListener('abort', signalListener);
    }
  };

  const delayPromise = new Promise((resolve, reject) => {
    settle = () => {
      cleanup();
      if (willResolve) {
        resolve(value);
      } else {
        reject(value);
      }
    };

    rejectFn = reject;
    timeoutId = (set || setTimeout)(settle, ms);
  });

  if (signal) {
    signal.addEventListener('abort', signalListener, { once: true });
  }

  // @ts-ignore
  delayPromise.clear = () => {
    timeoutId !== null && clear(timeoutId);
    timeoutId = null;
    settle();
  };

  return delayPromise;
};

const delay = createDelay({ willResolve: true });
// @ts-ignore
delay.reject = createDelay({ willResolve: false });
// @ts-ignore
delay.createWithTimers = ({ clearTimeout, setTimeout }) => {
  const delay = createDelay({ clearTimeout, setTimeout, willResolve: true });
  // @ts-ignore
  delay.reject = createDelay({ clearTimeout, setTimeout, willResolve: false });
  return delay;
};

export default delay;
