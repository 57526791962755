import delay from './index';

export interface minDelayOptions {
  delayRejection?: boolean;
}

const minDelay = async (promise: Promise<any>, minimumDelay: number, options?: minDelayOptions) => {
  const _options = {
    delayRejection: true,
    ...options,
  };

  let promiseError;

  if (_options.delayRejection) {
    promise = promise.catch(error => {
      promiseError = error;
    });
  }

  const value = await Promise.all([promise, delay(minimumDelay)]);
  return promiseError ? Promise.reject(promiseError) : value[0];
};

export default minDelay;
