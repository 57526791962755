/**
 * 是否为undefined
 * @param value
 */
function isUndefined(value) {
  return value === void 0;
}
/**
 * 是否为undefined字符串
 * @param value
 */


export function isUndefinedStr(value) {
  return value === 'undefined';
}
export default isUndefined;