import { RequestOptionsArgs } from './interfaces';
import { RequestOptions } from './request_options';
import { Headers } from './headers';
import { Request } from './request';
import { Response } from './response';
import { ResponseOptions } from './response_options';

export interface InterceptorRequestOptionsArgs extends RequestOptionsArgs {
  /**
   * Default false, Skip register interceptor
   */
  skipInterceptor?: boolean | null;
  /**
   * Default true, Does not wait for the lock but passes through the interceptor
   */
  waitLock?: boolean | null;
  /**
   * Transparent transmission additional configuration
   */
  extra?: any;
}

export interface InterceptorRequestArgs extends InterceptorRequestOptionsArgs {
  url: string | null;
}

export class InterceptorRequestOptions extends RequestOptions {
  skipInterceptor?: boolean | null;
  waitLock?: boolean | null;
  extra?: any;

  constructor(opts: InterceptorRequestOptionsArgs = {}) {
    const { waitLock, skipInterceptor, extra, ...requestOptions } = opts;
    super(requestOptions);

    this.waitLock = waitLock != null ? waitLock : null;
    this.skipInterceptor = skipInterceptor != null ? skipInterceptor : null;
    this.extra = extra;
  }

  merge(options?: InterceptorRequestOptionsArgs): InterceptorRequestOptions {
    return new InterceptorRequestOptions({
      method: options && options.method != null ? options.method : this.method,
      headers: new Headers(this.headers).merge(options?.headers),
      body: options && options.body != null ? options.body : this.body,
      url: options && options.url != null ? options.url : this.url,
      params: options && this._mergeSearchParams(options.params || options.search),
      withCredentials:
        options && options.withCredentials != null ? options.withCredentials : this.withCredentials,
      responseType:
        options && options.responseType != null ? options.responseType : this.responseType,
      timeOut: options && options.timeOut != null ? options.timeOut : this.timeOut,
      waitLock: options && options.waitLock != null ? options.waitLock : this.waitLock,
      signal: options && options.signal != null ? options.signal : this.signal,
      skipInterceptor:
        options && options.skipInterceptor != null ? options.skipInterceptor : this.skipInterceptor,
      extra: options && options.extra != null ? options.extra : this.extra,
    });
  }
}

export class InterceptorRequest extends Request {
  skipInterceptor: boolean;
  waitLock: boolean;
  extra: any;

  constructor(interceptorRequestArgs: InterceptorRequestArgs) {
    const { skipInterceptor, waitLock, extra, ...requestOptions } = interceptorRequestArgs;
    super(requestOptions);

    this.skipInterceptor = skipInterceptor!;
    this.waitLock = waitLock!;
    this.extra = extra!;
  }
}

export class InterceptorResponse extends Response {
  request: InterceptorRequest;

  constructor(responseOptions: ResponseOptions) {
    super(responseOptions);

    this.request = responseOptions.request as InterceptorRequest;
  }
}
