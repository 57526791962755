// import React from 'react';
import Loadable from '@loadable/component';
// import styled from 'styled-components';
// import { LoadingOutlined } from '@ant-design/icons';
// import styleConsts from '@/styles/vars/index.scss';
import minDelay from '@/libs/delay/min-delay';

// const CenterLoading = styled.div`
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

export function loadable(renderFn: () => Promise<any>, options: { delay?: number } = {}) {
  const { delay = 0 } = options;
  return Loadable(
    // () =>
    //   import(
    //     /* webpackChunkName: "Create" */
    //     /* webpackPrefetch: true */
    //     'pages/Create/index'
    //   ),
    () => (delay ? minDelay(renderFn(), delay) : renderFn())
    // {
    //   fallback: (
    //     <CenterLoading>
    //       <LoadingOutlined
    //         style={{
    //           color: styleConsts.blue,
    //           fontSize: 30,
    //         }}
    //       />
    //     </CenterLoading>
    //   ),
    // }
  );
}
