import remConst from '@/styles/rem.scss';

function fontSizeInit() {
  const html = document.documentElement;
  let hWidth = html.getBoundingClientRect().width;
  if (hWidth >= 600) {
    hWidth = 414;
  }
  html.style.fontSize = hWidth / 10 + 'px';
}

function register() {
  // @ts-ignore
  window.onload = fontSizeInit();
  window.onresize = function () {
    fontSizeInit();
  };
}

function pxToRem($px: number) {
  return `${$px / parseInt(remConst.designDraftWidth)}rem`;
}

export { register, pxToRem };
