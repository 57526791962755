import { initAndBind } from '../../core';
import { IOptions } from '../../core/types/options';
import { WXClient } from './client';
import { globalErrorHandler, addSystemInfoEXT, checkStorage } from './globalHandlers';

export function mount(options: IOptions) {
  try {
    initAndBind(WXClient, options);
    globalErrorHandler();
    addSystemInfoEXT();
    checkStorage();
    // TODO 性能监控
  } catch (e) {
    console.log('mount logger error', e);
  }
}
