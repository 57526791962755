import { ControlType, MediaType } from 'braft-editor';

import { getUploadUrlAndParams, getUploadResult, UploadResultState } from '@pinweb/services';

// some unreliable controls are forbidden
export const defaultControls: ControlType[] = [
  'font-size',
  // 'line-height',
  // 'letter-spacing',
  'separator',
  'text-color',
  'bold',
  'italic',
  'underline',
  'strike-through',
  'separator',
  'superscript',
  'subscript',
  'remove-styles',
  'emoji',
  'separator',
  'text-indent',
  'text-align',
  'separator',
  'headings',
  'list-ul',
  'list-ol',
  'blockquote',
  'code',
  'separator',
  'link',
  'separator',
  'hr',
  'separator',
  'media',
  'separator',
  'clear',
  'fullscreen',
];

export type MediaParamsOptions = {
  preUploadReq?: Partial<storage.UploadResourceReq>;
};

export const mediaParams: (opt: MediaParamsOptions) => MediaType = opt =>
  ({
    async uploadFn(param) {
      try {
        const { uploadUrl, uploadParams, resp } = await getUploadUrlAndParams({
          content_type: param.file.type,
          ...opt?.preUploadReq,
        });

        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        for (const key in uploadParams) {
          fd.append(key, uploadParams[key]);
        }

        const successFn = (response: ProgressEvent<XMLHttpRequest>) => {
          // 假设服务端直接返回文件上传后的地址
          // 调用param.progress告知编辑器上传成功后的文件地址
          if (response.target!.status >= 200 && response.target!.status <= 300) {
            getUploadResult({
              resource_id: resp.resource!.id!,
              resource_state: UploadResultState.StateSuccess,
            })
              .then(({ url = '' }) => {
                // @ts-ignore
                param.success({
                  url,
                });
              })
              .catch(err => {
                param.error({
                  msg: `[upload callback error]:${err}`,
                });
              });
          } else {
            param.error({
              msg: `[response.target!.status]:${response.target!.responseText}`,
            });
          }
        };

        const progressFn = (event: ProgressEvent) => {
          // 调用param.progress告知编辑器当前的上传进度
          param.progress((event.loaded / event.total) * 100);
        };

        const errorFn = (_response: ProgressEvent) => {
          // 调用param.progress告知编辑器上传发生了问题
          param.error({
            msg: 'unable to upload.',
          });
        };

        xhr.upload.addEventListener('progress', progressFn, false);
        xhr.addEventListener('load', successFn as any, false);
        xhr.addEventListener('error', errorFn, false);
        xhr.addEventListener('abort', errorFn, false);

        fd.append('file', param.file);
        xhr.open('POST', uploadUrl, true);
        xhr.send(fd);
      } catch (error) {
        param.error({
          msg: error,
        });
      }
    },
  } as MediaType);

export const converts = {
  // https://github.com/margox/braft-editor/issues/256
  blockExportFn(contentState: any, block: any) {
    const previousBlock = contentState.getBlockBefore(block.key);

    if (block.type === 'unstyled' && previousBlock && previousBlock.getType() === 'atomic') {
      return {
        start: '',
        end: '',
      };
    }
  },
};
