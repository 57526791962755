import React from 'react';
import { Modal } from 'antd';
import _ from 'lodash';

import { SchemaComponent } from '../core';
import { SchemaModalProps } from './interface';

const SchemaModal: React.FC<SchemaModalProps> = React.forwardRef<typeof Modal, SchemaModalProps>(
  (props, ref) => {
    const { children, footer, _children, onOk, ...otherProps } = props;
    const [confirmLoading, setConfirmLoading] = React.useState(false);

    React.useEffect(() => {
      if (footer) {
        otherProps.footer = <SchemaComponent {...footer} />;
      }
    }, [footer, otherProps]);

    const renderChild = React.useMemo(() => {
      if (_children) {
        return <SchemaComponent _children={_children} />;
      }
      return children;
    }, [_children, children]);

    return (
      <Modal
        // @ts-ignore
        ref={ref as any}
        confirmLoading={confirmLoading}
        footer={footer}
        {...otherProps}
        onOk={e => {
          if (onOk) {
            const p = onOk.call(null, e) as void | Promise<any>;
            if (p instanceof Promise && !_.has(otherProps, 'confirmLoading')) {
              setConfirmLoading(true);
              Promise.resolve(p).finally(() => setConfirmLoading(false));
            }
          }
        }}
      >
        {renderChild}
      </Modal>
    );
  }
);

export default SchemaModal;
