import {
  AbortController,
  ConnectionAdapter,
  Headers,
  InterceptorHttp,
  InterceptorRequestOptions,
  RequestMethod,
  Response,
  ResponseContentType,
} from '@pinweb/http';
import {
  InterceptorRequestArgs,
  InterceptorRequestOptionsArgs,
} from '@pinweb/http/typings/interceptor_interfaces';
import { hasWindow, uuid } from '@pinweb/utils';
import { logger } from '@pinweb/logger';
import { env } from '@pinweb/runtime';

import {
  HEADER_APPID_KEY,
  HEADER_CACHE_EXPIRED_KEY,
  HEADER_CORP_ID_KEY,
  HEADER_DEVICE_REQID_KEY,
  HEADER_DEVICE_SID_KEY,
  HEADER_NONCE_KEY,
  HEADER_REQUESTID_KEY,
  HEADER_VERSION_KEY,
} from './constants';
import { IClientOptions, setClient } from '@pinweb/proto-api-client';

// TODO apiServices 类型完善
/** 接口请求第二个参数类型  **/
export type BrickHttpClientOptions = InterceptorRequestOptionsArgs &
  Partial<IClientOptions> & {
    /** 毫秒数, 同接口同参数，这段时间内只发一次请求 **/
    debounce?: number;
    /** 忽略后端缓存控制，强制发起请求 **/
    isOmitCache?: boolean;
    /**
     * 忽略频率控制
     * 现在web端兜底16ms内相同接口参数只请求一次
     */
    skipFrequencyLimit?: boolean;
    /** 开启防重放攻击 **/
    enableNonce?: boolean;
    host?: string;
  };

/**
 * brick 后端接口通用参数
 * @param options
 */
export function getDefaultBrickRequestOptions(
  options: InterceptorRequestOptionsArgs = {}
): Partial<InterceptorRequestArgs> {
  const { appId, PKGName, corpId, mode, version } = process.env;
  const { headers, ...opt } = options;

  return {
    timeOut: 10000,
    responseType: ResponseContentType.Json,
    headers: new Headers({
      'content-type': 'application/json',
      [HEADER_VERSION_KEY]: `${PKGName}-${mode}-${version || '0.0.1'}`,
      [HEADER_CORP_ID_KEY]: corpId ? String(corpId) : '',
      [HEADER_APPID_KEY]: appId ? String(appId) : '',
      [HEADER_DEVICE_SID_KEY]: env.deviceSid!,
    }).merge(headers),
    ...opt,
  };
}

/**
 * 通过传入 apiService 的接口函数，返回接口相关参数
 * @param fn
 * @param opt
 */
export function getBrickApiServicesRequestOptions<Req, Rsp>(
  fn: (r: Req, _o?: IClientOptions) => Promise<Rsp>,
  opt: {
    req: Req;
    headers?: Headers | Record<string, string>;
    host?: string;
  }
) {
  const { req, headers } = opt;
  const path = String(fn(req, { client: (_: any, o?: any) => o!.url } as any));
  const options = getDefaultBrickRequestOptions({ headers: new Headers(headers) });
  let host = '';
  if (!opt.host && !hasWindow()) {
    host = process.env.baseApi!;
  }
  return {
    path: `${host}/gateway${path}`,
    headers: options.headers?.toJSON(),
    data: req,
    timeout: options.timeOut,
  };
}

/**
 * 获取请求client 实例 默认配置
 * {
      timeOut: 5000,
      method: RequestMethod.Post,
      responseType: ResponseContentType.Json,
      headers: new Headers({
        "content-type": "application/json",
        [HEADER_VERSION]: `${PKGName}-${mode}-${version}`,
        [HEADER_CORP_ID_KEY]: String(appId),
        [HEADER_APPID_ID_KEY]: String(corpId),
      })
    }
 * @param opts InterceptorRequestOptionsArgs | InterceptorRequestOptions
 * @param adapter ConnectionAdapter default is XMLRequest
 */
export function getBrickRPCClientInstance(
  opts?: InterceptorRequestOptionsArgs | InterceptorRequestOptions,
  adapter?: ConnectionAdapter
): InterceptorHttp {
  if (opts instanceof InterceptorRequestOptions) {
    return new InterceptorHttp(opts, adapter);
  }

  return new InterceptorHttp(
    new InterceptorRequestOptions(getDefaultBrickRequestOptions(opts!)),
    adapter
  );
}

/**
 * 生成缓存的key 规则是 按照 url method body 生成 str key
 * @param url
 * @param method
 * @param body
 */
export function generateCacheKey({
  url,
  method,
  body,
}: {
  url: string;
  method: RequestMethod;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
}): string {
  let bodyText = '';
  if (typeof body === 'object') {
    bodyText = JSON.stringify(body);
  } else if (body) {
    bodyText = body.toString();
  }
  return url + method + bodyText;
}

/**
 * 接口缓存，放在内存
 */
const API_CACHE_MAP: {
  [key: string]: {
    /** 更新时间 **/

    t: number;

    /** 缓存数据 **/
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    d: any;
  };
} = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function checkAPICache<T extends any>(key: string): Promise<T> {
  for (const key in API_CACHE_MAP) {
    if (new Date().getTime() / 1000 > API_CACHE_MAP[key].t) delete API_CACHE_MAP[key];
  }
  if (API_CACHE_MAP[key]) {
    console.warn(`${key} is from cache`);
    return Promise.resolve(API_CACHE_MAP[key].d);
  }
  return Promise.reject();
}

export function injectBrickDeviceReqIdInterceptor(http: InterceptorHttp) {
  http.interceptor.request.use(res => {
    if (!res.headers.get(HEADER_DEVICE_REQID_KEY)) {
      // 新增请求 device req id
      res.headers.set(HEADER_DEVICE_REQID_KEY, uuid());
    }
    if (!res.headers.get(HEADER_DEVICE_SID_KEY)) {
      res.headers.set(HEADER_DEVICE_SID_KEY, env.deviceSid!);
    }
    if (res?.extra?.enableNonce && env?.nonceId) {
      res.headers.set(HEADER_NONCE_KEY, env.nonceId!);
    }
    return res;
  });
}

/**
 * 校验brick 返回参数
 * @note 这个要放到业务handler之前
 * @param http
 */
export function injectBrickRPCResponseInterceptor(http: InterceptorHttp): void {
  injectBrickDeviceReqIdInterceptor(http);
  http.interceptor.response.use(
    res => {
      try {
        // TODO 如果不是json 返回就GG了,现在都是json，有问题会被拦截器拦截
        const data = res.json();
        const code = data.errcode || data.code || 0;

        const commonHeaders = {
          [HEADER_REQUESTID_KEY]: res.headers!.get(HEADER_REQUESTID_KEY)! || data?.hint,
          [HEADER_DEVICE_REQID_KEY]: res.request.headers.get(HEADER_DEVICE_REQID_KEY),
        };

        // 接口响应慢上报日志
        if (res.duration! > 2000) {
          logger.error({
            name: 'slow api',
            message: res.url,
            ext: {
              [HEADER_DEVICE_REQID_KEY]: res.request.headers.get(HEADER_DEVICE_REQID_KEY),
              time: res.duration,
            },
          });
        }
        if (code === 0) {
          const result = data.data || data;
          // 当客户端强制拉取，不缓存
          if (res.request?.extra?.isOmitCache) return result;
          try {
            // 当接口返回缓存控制头，内存中保存一下返回结果
            const t = res?.headers?.get(HEADER_CACHE_EXPIRED_KEY);
            if (t) {
              API_CACHE_MAP[
                generateCacheKey({
                  url: res.url!,
                  method: res.request?.method!,
                  body: res.request?.text(),
                })
              ] = {
                t: +t,
                d: result,
              };
            }
          } catch (e) {}
          return result;
        } else if (code < 0) {
          // 上报非业务报错
          logger.error({
            name: `request error: ${res.url}`,
            message: `${code}:${data.errmsg || data.error || ''}`,
            ext: {
              data,
              ...commonHeaders,
            },
          });
        }
        console.log('%crequest error', 'color:red;', {
          data,
          ...commonHeaders,
          request: res.request,
        });
        return Promise.reject(res);
      } catch (err) {
        logger.error({
          name: `injectBrickRPCResponseInterceptor error: ${res.url}`,
          message: '',
          ext: { err },
        });
        return Promise.reject(err);
      }
    },
    err => {
      // 上报请求错误（框架有捕获到的） 返回body为空的时候
      if (err instanceof Response) {
        if (!err.text()) {
          logger.error({
            name: `request rejected handler: ${err.toString()}`,
            message: err.statusText!,
            ext: {
              err,
              [HEADER_DEVICE_REQID_KEY]: err.request?.headers.get(HEADER_DEVICE_REQID_KEY),
            },
          });
          return Promise.reject(err.toString());
        }
      } else {
        // 未捕获的异常
        logger.captureException(err);
      }
      return Promise.reject(err);
    }
  );
}

/**
 * 注册 apiServices client
 * @param httpInterceptor
 * @param host
 */
export function registerAPIServiceClient(httpInterceptor: InterceptorHttp, host = ''): void {
  const frameAbortCtlMap = new Map<string, AbortController[]>();
  setClient((r, o?: BrickHttpClientOptions) => {
    let {
      url,
      waitLock,
      skipInterceptor,
      headers,
      debounce,
      method = RequestMethod.Post,
      params = {},
      timeOut,
      signal,
      enableNonce,
      skipFrequencyLimit = false,
      ...extra
    } = o!;

    try {
      // 如果没设置host 小程序环境自动添加host
      if (!host && !hasWindow()) {
        host = process.env.baseApi!;
        // 如果是相对协议，兼容一下
        if (host.startsWith('//')) {
          host = `https:${host}`;
        }
      }

      // 如果是浏览器，页面地址跟baseApi不匹配的时候自动设置api域名
      if (
        !host &&
        hasWindow() &&
        process.env.BABEL_ENV === 'production' &&
        window.location.origin !== process.env.baseApi
      ) {
        host = process.env.baseApi!;
      }
    } catch (e) {}

    // TODO 封装下
    const reqUrl = `${o?.host || host}/gateway${url}`;

    // check 兼容GET情况,保证正常命中缓存
    if (method === RequestMethod.Get || String(method).toLocaleUpperCase() === 'GET') {
      Object.assign(params, r);
    }

    // 生成请求唯一标识
    const cacheKey = generateCacheKey({
      url: reqUrl,
      method: method as RequestMethod,
      body: r,
    });

    // 浏览器环境下,取消浏览器一帧内的请求,兜底逻辑,怕前端逻辑有问题DDOS服务器
    if (!signal && hasWindow() && !skipFrequencyLimit) {
      const abortController = new AbortController();
      signal = abortController.signal;
      const res = frameAbortCtlMap.get(cacheKey);
      if (res) {
        res.push(abortController);
        frameAbortCtlMap.set(cacheKey, res);
      } else {
        frameAbortCtlMap.set(cacheKey, []);
        const requestAnimation = window.requestAnimationFrame || window.webkitRequestAnimationFrame;
        requestAnimation(() => {
          const _res = frameAbortCtlMap.get(cacheKey);
          if (_res?.length) {
            _res?.map(v => v.abort());
          }
          frameAbortCtlMap.delete(cacheKey);
        });
      }
    }

    function request() {
      return httpInterceptor.request(reqUrl, {
        url: reqUrl,
        extra: {
          ...(extra || {}),
          enableNonce,
        },
        body: r,
        waitLock,
        skipInterceptor,
        method,
        headers,
        params,
        timeOut,
        signal,
      });
    }

    // 如果请求带了debounce 防止多次请求
    if (debounce) {
      return checkAPICache(cacheKey).catch(() => {
        const result = request();
        API_CACHE_MAP[cacheKey] = {
          t: (new Date().valueOf() + +(debounce || 0)) / 1000,
          d: result,
        };
        return result;
      });
    } else {
      return request();
    }
  });
}

/**
 * 获取apiService 方法里面的参数
 * @param fn
 */
export function getApiServiceOpts(fn: IClientOptions['client']): IClientOptions {
  return (fn!({}, {
    client: (_: any, o: any) => o,
  } as IClientOptions) as unknown) as IClientOptions;
}
