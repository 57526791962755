import React from 'react';
import { omit, last } from 'lodash';
import { Upload, Button, message, Modal } from 'antd';
import { UploadProps, RcFile } from 'antd/lib/upload';
import { byteToReadableSize } from '@pinweb/utils';
import { logger } from '@pinweb/logger';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';

import { measureImgResolution } from './services';

import {
  UploadParams,
  getUploadUrlAndParams,
  getUploadResult,
  UploadResultState,
} from '@pinweb/services';

export type IUploaderValue = string[] | any;

const replaceErrorFileInList = (file: UploadFile, fileList: UploadFile[]): UploadFile[] => {
  const _fileList = [...fileList];
  _fileList.splice(
    fileList.findIndex(item => item.uid === file.uid),
    1,
    { ...(omit(file, ['url']) as UploadFile), status: 'error', thumbUrl: '' }
  );
  return _fileList;
};

export interface IUploaderProps<Input = any> extends Omit<UploadProps, 'onChange'> {
  value?: IUploaderValue;
  /** 拖拽上传 */
  dragger?: boolean;
  preUploadReq?: Partial<storage.UploadResourceReq>;
  /**
   * 上传文件是否需要带后缀
   */
  suffixFileType?: boolean;
  beforeUpload?: ((file: RcFile, FileList: RcFile[]) => boolean | PromiseLike<void>) | undefined;
  maxFileSize?: number | [number, number];
  maxLen?: number;
  fileOverSizeTip?: React.ReactNode;
  incorrectFileTypeTip?: React.ReactNode;
  resolution?: string;
  onChange?: (files: string[]) => void;
  /**
   * 是否自动替换fileList文件名为OSS链接
   */
  replaceOSSFileName?: boolean;
  onFileOverSize?(file: UploadFile): PromiseLike<boolean | void> | boolean;
  onResolutionNotMatch?(
    file: UploadFile,
    size: { width: number; height: number }
  ): PromiseLike<boolean | void> | boolean;
  inputFormatter?(params: Input): string[];
  outputFormatter?(params: string[]): any;

  /**
   * 业务参数传递上传对用后端的资源模型
   * @param resourceMap
   */
  onResourceChange?(resourceMap: Record<string, storage.ModelResource>): void;
  onFileChange?: (info: UploadChangeParam<UploadFile>) => void;
}

export interface IUploaderState {
  uploadUrl: string;
  uploadParams: UploadParams;
  fileList: UploadFile[];
  preUploading: boolean;
}

class Uploader extends React.Component<IUploaderProps, IUploaderState> {
  static defaultProps = {
    maxFileSize: 2097152,
    /**
     * 默认结尾会带上文件大小
     */
    fileOverSizeTip: '文件大小不能超过',
    incorrectFileTypeTip: '文件类型不正确',
    suffixFileType: false,
    replaceOSSFileName: true,
  };

  state: IUploaderState = {
    uploadUrl: '',
    uploadParams: null,
    fileList: [],
    preUploading: false,
  };

  preUploadRsp: {
    [uid: string]: storage.ModelResource;
  } = {};

  uploadCallBackRsp: {
    [uid: string]: storage.UploadCallBackV2Rsp;
  } = {};

  AntUploadRef = React.createRef<UploadProps>();

  processPreUpload = async (file: RcFile) => {
    try {
      const preUploadReq: weblogic.PreUploadReq = {
        content_type: '',
        ...this.props.preUploadReq,
      };
      preUploadReq!.content_type = this.getFileType(file)!;
      try {
        if (this.props.suffixFileType) {
          preUploadReq!.file_suffix = file.name!.split('.').pop();
        }
      } catch (e) {}

      if (!preUploadReq!.content_type) {
        message.error('未知文件类型');
        return Promise.reject(new Error('未知文件类型'));
      }

      const {
        uploadUrl,
        uploadParams,
        resp: { resource },
      } = await getUploadUrlAndParams(preUploadReq);

      this.preUploadRsp[file.uid!] = resource!;

      this.setState({ uploadUrl, uploadParams });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  beforeUpload: (file: RcFile, FileList: RcFile[]) => PromiseLike<void> = async (
    file: RcFile,
    FileList: RcFile[]
  ) => {
    try {
      this.setState({ preUploading: true });
      await this.handleCustomBeforeUpload(file, FileList);
      await this.checkFileType(file);
      await this.checkFileSize(file);
      await this.checkResolutionIsMatch(file);
      await this.processPreUpload(file);
    } catch (e) {
      console.log('beforeUpload', e);
      return Promise.reject(e);
    } finally {
      this.setState({ preUploading: false });
    }
  };

  isOverMaxLen = () => {
    const { value, maxLen } = this.props;
    const { fileList } = this.state;

    let result;
    if (Array.isArray(value)) {
      result = maxLen && (fileList.length >= maxLen || value.length >= maxLen);
    } else {
      result = (fileList.map(item => item.status).includes('uploading') || value) && maxLen;
    }
    return result;
  };

  checkFileSize = async (file: UploadFile) => {
    const { maxFileSize, fileOverSizeTip, onFileOverSize } = this.props;
    // check file size
    try {
      const warningSize = Array.isArray(maxFileSize)
        ? maxFileSize.sort((a, b) => a - b)[0]
        : maxFileSize;
      const errorSize = Array.isArray(maxFileSize)
        ? maxFileSize.sort((a, b) => a - b)[1]
        : maxFileSize;

      if (!warningSize || !errorSize) {
        return;
      }

      // 如果有检查文件大小回调
      if (onFileOverSize && (file.size > errorSize || file.size > warningSize)) {
        try {
          const resp = await onFileOverSize(file);
          if (resp === false) {
            return Promise.reject();
          }
        } catch (e) {
          return Promise.reject(e);
        }
        // 超出最大限制大小
      } else if (file.size > errorSize) {
        console.log('{ errorSize }', byteToReadableSize(errorSize));

        message.error(`${fileOverSizeTip}${byteToReadableSize(errorSize)}`);
        return Promise.reject(fileOverSizeTip);
        // 超出提示大小
      } else if (file.size > warningSize) {
        return new Promise((resolve, reject) => {
          Modal.confirm({
            title: '文件超过建议大小',
            content: `建议上传文件大小不超过${byteToReadableSize(
              warningSize
            )}，当前文件约为${byteToReadableSize(file.size)}是否继续上传？`,
            onOk: () => {
              resolve(file);
            },
            onCancel: () => {
              reject(file);
            },
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * file.type 有兼容问题，会存在空的情况，不能作为唯一判断条件
   *  https://developer.mozilla.org/zh-CN/docs/Web/HTTP/Basics_of_HTTP/MIME_types
   */
  getFileType(file: UploadFile) {
    return file.type || `.${last(file.name.split('.'))}`;
  }

  checkFileType = (file: UploadFile) => {
    const { accept, incorrectFileTypeTip } = this.props;
    if (accept) {
      try {
        // https://stackoverflow.com/questions/10688588/which-mime-type-should-i-use-for-mp3
        let fa = accept;
        const type = this.getFileType(file);

        if (fa.includes('.mp3')) {
          fa += ',audio/mpeg,audio/mp3,audio/mpeg3,audio/x-mpeg-3,video/mpeg,video/x-mpeg';
        }

        if (accept && !new RegExp(fa.split(',').join('|'), 'ig').test(type)) {
          console.error(`${incorrectFileTypeTip},当前文件类型：${type}`);
          message.error(incorrectFileTypeTip);
          return Promise.reject(incorrectFileTypeTip);
        }
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      }
    }
  };

  checkResolutionIsMatch = async (file: UploadFile) => {
    const { resolution = '', onResolutionNotMatch } = this.props;
    try {
      if (resolution) {
        const imgSize = await measureImgResolution(file);
        const resolutions = resolution.split('/');
        const isWidthMatch = imgSize.width === Number(resolutions[0]);
        const isHeightMatch = imgSize.height === Number(resolutions[1]);
        if (isWidthMatch && isHeightMatch) {
          return Promise.resolve();
        } else if (onResolutionNotMatch) {
          const result = await onResolutionNotMatch(file, imgSize);
          if (result === false) {
            return Promise.reject();
          }
        }
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  componentDidMount() {
    this.handleOriginalFiles();
  }

  componentDidUpdate() {
    this.handleOriginalFiles();
  }

  handleOriginalFiles = () => {
    const { value = [] } = this.props;
    let { fileList } = this.state;
    try {
      const propfileList = this.parseInput(value)
        .map(item => item.url)
        .filter(v => v);
      const stateFileList = this.state.fileList.map(item => item.url).filter(v => v);
      if (
        !(
          propfileList.length === stateFileList.length &&
          propfileList.every(item => stateFileList.includes(item))
        )
      ) {
        fileList = this.parseInput(value);
        this.setState({ fileList });
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleCustomBeforeUpload = async (file: RcFile, FileList: RcFile[]): Promise<void> => {
    const { beforeUpload } = this.props;
    try {
      if (beforeUpload) {
        const res = await beforeUpload(file, FileList);
        // if return false, then stop uploading
        if (res === false) {
          return Promise.reject(res);
        }
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  parseInput: (files: IUploaderValue) => UploadFile[] = files => {
    // call inputFormatter at the beginning
    const resolvedFiles = this.props.inputFormatter
      ? this.props.inputFormatter(files) || []
      : files;

    const fileUrls = this.state.fileList.map(item => item.url);
    const maxNumUid =
      this.state.fileList
        .map(item => +item.uid)
        .filter(uid => !isNaN(uid))
        .sort((a, b) => a - b)
        .pop() || 1;

    return resolvedFiles.map((file: string | UploadFile, i: number) => {
      const isString = typeof file === 'string';
      const isIncluded: boolean = isString && fileUrls.includes(file as string);
      if (isIncluded) {
        return this.state.fileList.find(item => item.url === file);
      }

      return isString
        ? {
            uid: `-${i - maxNumUid}`,
            url: file,
            name: file,
          }
        : file;
    }) as UploadFile[];
  };

  parseOutput = (fileList: UploadFile[] = []) => {
    const parsedFiles = fileList
      .map(file => {
        if (!file.url) {
          if (this.uploadCallBackRsp[file.uid]) {
            file.url = this.uploadCallBackRsp[file.uid].url;
          }
        }
        // upload success return url
        // else return file object
        return file.url || file;
      })
      // only output the successful ones
      .filter(item => typeof item === 'string') as string[];

    return this.props.outputFormatter ? this.props.outputFormatter(parsedFiles) : parsedFiles;
  };

  handleChange: (info: UploadChangeParam<UploadFile>) => void = async info => {
    let fileList = [...info.fileList];
    let file = { ...info.file };
    try {
      try {
        // upload error notify backend
        switch (file.status) {
          case 'error':
            try {
              await getUploadResult({
                resource_id: this.preUploadRsp[file.uid].id!,
                resource_state: UploadResultState.StateFail,
              });
            } catch (err) {
              logger.captureException(err);
            } finally {
              // 找到当前的文件去除Url，显示默认错误样式
              fileList = replaceErrorFileInList(file, fileList);
              file = { ...file, status: 'error' };
            }
            break;
          case 'done':
            try {
              this.uploadCallBackRsp[file.uid] = await getUploadResult({
                resource_id: this.preUploadRsp[file.uid].id!,
                resource_state: UploadResultState.StateSuccess,
              });

              this.props.onResourceChange &&
                this.props.onResourceChange(
                  Object.values(this.uploadCallBackRsp).reduce<
                    Record<string, storage.ModelResource>
                  >((acc, { url, resource }) => {
                    acc[url!] = resource!;
                    return acc;
                  }, {})
                );
            } catch (err) {
              logger.captureException(err);
              // 找到当前的文件修改文件状态，去除Url，显示默认错误样式
              fileList = replaceErrorFileInList(file, fileList);
              file = { ...file, status: 'error' };
            }
            break;
          default:
            console.log(file.status);
            break;
        }
      } catch (error) {
        console.error(error);
      }

      if (!fileList.map(item => item.status).includes('uploading')) {
        const output = this.parseOutput(fileList);
        this.props.onChange && this.props.onChange(output);
      }

      this.props.onFileChange &&
        this.props.onFileChange({
          event: info.event,
          file,
          fileList: this.props.replaceOSSFileName
            ? fileList.map(item =>
                item === file && this.preUploadRsp[file.uid]
                  ? { ...item, name: this.preUploadRsp[item.uid].path }
                  : item
              )
            : fileList,
        });

      this.setState({ fileList });
    } catch (err) {
      console.error(err);
    }
  };

  handleRemove = (file: UploadFile<any>) => {
    if (Object.prototype.hasOwnProperty.call(this.uploadCallBackRsp, file.uid)) {
      delete this.uploadCallBackRsp[file.uid];
    }
    return this.props.onRemove && this.props.onRemove(file);
  };

  render() {
    const { uploadUrl, uploadParams, fileList, preUploading } = this.state;
    const {
      value,
      children,
      disabled,
      onRemove,
      fileList: propFileList,
      dragger = false,
      ...options
    } = this.props;

    if (dragger) {
      return (
        <Upload.Dragger
          {...options}
          disabled={disabled || preUploading}
          action={uploadUrl}
          beforeUpload={this.beforeUpload}
          onChange={this.handleChange}
          onRemove={this.handleRemove}
          data={uploadParams as object}
          fileList={propFileList || fileList}
          // @ts-ignore
          ref={this.AntUploadRef}
        >
          {children !== undefined ? children : !this.isOverMaxLen() ? <Button>上传</Button> : null}
        </Upload.Dragger>
      );
    }

    return (
      <Upload
        {...options}
        disabled={disabled || preUploading}
        action={uploadUrl}
        beforeUpload={this.beforeUpload}
        onChange={this.handleChange}
        onRemove={this.handleRemove}
        data={uploadParams as object}
        fileList={propFileList || fileList}
        ref={this.AntUploadRef}
      >
        {children !== undefined ? children : !this.isOverMaxLen() ? <Button>上传</Button> : null}
      </Upload>
    );
  }
}

export default Uploader;
