import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import 'react-app-polyfill/stable';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { setApiClient } from '@/services/setApiClient';

import { BrowserRegister } from '@pinweb/pin-register';
import { ViewportProvider } from '@pinweb/react-components';
import { register as registerRem } from '@/services/rem';
import stores from '@/stores';
import './App.less';
import './styles/index.scss';

console.warn('app env', process.env);

BrowserRegister();
setApiClient();
registerRem();

ReactDOM.render(
  <Provider {...stores}>
    <ViewportProvider>
      <App />
    </ViewportProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
