import React from 'react';
import { ThemeContext } from 'styled-components';

interface PageTitleProps {
  /** 页面标题 **/
  title: string;
  /** 右边自定义 **/
  renderRight?: React.ReactElement;
}

const PageTitle: React.FC<PageTitleProps> = props => {
  const themeContext = React.useContext(ThemeContext);

  return (
    <div
      className="basis-layout--page-title"
      style={{
        lineHeight: themeContext.headerHeight,
        padding: `0 ${themeContext.baseMargin}`,
        backgroundColor: '#fff',
      }}
    >
      <span
        style={{
          color: 'rgba(0,0,0,.85)',
          fontWeight: 600,
          fontSize: 20,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {props.title}
      </span>
      {props.renderRight}
    </div>
  );
};

export default PageTitle;
