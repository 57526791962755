import React from 'react';
import { Switch, Route, SwitchProps, Redirect } from 'react-router';

import { RouteConfig } from './interfaces';

function renderRoutes(routes: RouteConfig[], extraProps: any = {}, switchProps: SwitchProps = {}) {
  return routes ? (
    <Switch {...switchProps}>
      {routes.map((route, i) => {
        if (Object.prototype.hasOwnProperty.call(route, 'to')) {
          return (
            <Redirect
              key={route.key || i}
              to={route.to!}
              path={route.path as string}
              from={route.from}
              strict={route.strict}
              exact={route.exact}
            />
          );
        }
        const Component = route.component!;
        return (
          <Route
            key={route.key || i}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            render={props =>
              route.render ? (
                route.render({ ...props, ...extraProps, route: route })
              ) : (
                <Component {...props} {...extraProps} route={route} />
              )
            }
          />
        );
      })}
    </Switch>
  ) : null;
}

export default renderRoutes;
