import _typeof from "@babel/runtime/helpers/esm/typeof";

/**
 * 检查是否浏览器环境
 */
export function isBrowser() {
  return (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && window.window === window;
}
/**
 * 检查是否node环境
 */

export function isNode() {
  return typeof process !== 'undefined' && process.release.name === 'node';
}
/**
 * 是否为safari浏览器
 */

export function isSafari() {
  var ua = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.navigator.userAgent;
  return !ua.match(/Chrome/gi) && !!ua.match(/Safari/gi);
}
/**
 * 是否为chrome浏览器
 */

export function isChrome() {
  var ua = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.navigator.userAgent;
  return !!ua.match(/Chrome/gi);
}
/**
 * 是否为firefox浏览器
 */

export function isFirefox() {
  var ua = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.navigator.userAgent;
  return !!ua.match(/Firefox/gi);
}
/**
 * 是否为opera浏览器
 */

export function isOpera() {
  var ua = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.navigator.userAgent;
  return !!ua.match(/Opera/gi);
}
/**
 * 是否为IE浏览器
 */

export function isIE() {
  var UA = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.navigator.userAgent.toLowerCase();
  return UA && /msie|trident/.test(UA);
}
export function isIE8() {
  var UA = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.navigator.userAgent.toLowerCase();
  return UA && UA.indexOf('msie 8.0') > 0;
}
export function isIE9() {
  var UA = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.navigator.userAgent.toLowerCase();
  return UA && UA.indexOf('msie 9.0') > 0;
}
export function isEDGE() {
  var UA = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.navigator.userAgent.toLowerCase();
  return UA && UA.indexOf('edge/') > 0;
}
/**
 * 是否为IOS系统
 */

export function isIOS() {
  var ua = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.navigator.userAgent;
  return !!ua.match(/iphone|ipad|iPod/gi);
}
/**
 * 是否为Android系统
 */

export function isAndroid() {
  var ua = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.navigator.userAgent;
  return !!ua.match(/android/gi);
}
/**
 * 是否是微信浏览器
 * @param ua
 */

export function isWXBrowser() {
  var ua = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.navigator.userAgent;
  return !!ua.match(/micromessenger/gi);
}
/**
 * 判断是否企业微信浏览器
 * @param ua
 */

export function isWWBrowser() {
  var ua = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.navigator.userAgent;
  return !!ua.match(/wxwork/gi) && isWWBrowser(ua);
}