import getType from './get-type';
/**
 * 判断是否为number
 * @param value
 */

function isNumber(value) {
  return getType(value) === 'Number';
}

export default isNumber;