import React from 'react';

export const StoreContext = React.createContext(null);

export interface ProviderProps {
  value: any;
}

export const Provider: React.FC<ProviderProps> = ({ children, value }) => {
  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};
