import styled from 'styled-components';

export const HelperContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 5px;
  box-shadow: 0 2px 8px #f0f1f2;
  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

export const HelperItem = styled.div`
  height: 30px;
  line-height: 30px;
  text-align: center;
`;
