/**
 * 头部sid 优先级高于 cookie: sid={sid}
 */
export const HEADER_AUTHORIZATION_KEY = 'x-pin-authorization';

/**
 * 头部账号SID account_sid
 */
export const HEADER_ACCOUNT_AUTHORIZATION_KEY = 'x-pin-account-sid';

/**
 * corp id 企业ID
 */
export const HEADER_CORP_ID_KEY = 'x-pin-corpid';

/**
 * appid id 企业下应用id
 */
export const HEADER_APPID_KEY = 'x-pin-appid';

/**
 * 当前客户端版本， 建议拼装格式  appName-env-version
 */
export const HEADER_VERSION_KEY = 'x-requested-with';

/**
 * 防御重放工具，根据 proto 内 ext.EnableNonce 自动生成
 */
export const HEADER_NONCE_KEY = 'x-pin-nonce';

/**
 * 接口返回的 reqid
 */
export const HEADER_REQUESTID_KEY = 'x-req-id';

/**
 * 前端请求携带的sid，前端生成唯一值  uuid
 */
export const HEADER_DEVICE_REQID_KEY = 'x-device-req-id';

/**
 * 当前客户端设备id，前段持久化存储
 */
export const HEADER_DEVICE_SID_KEY = 'x-device-sid';

/**
 * mock 数据header
 */
export const HEADER_MOCK_ID_KEY = 'x-mock-id';

/**
 * testing header key
 */
export const HEADER_TEST_ID_KEY = 'x-brick-test-id';

/**
 * 返回 not_match，表示未命中任何mock，此时后端会重新请求真实服务；否则视为mock成功
 */
export const HEADER_MOCK_SERVER_RSP_KEY = 'x-mock-rsp';

/**
 * mock 服务器返回header `${errcode},${base64(errmsg || '')}`
 */
export const HEADER_BRICK_RSP_KEY = 'x-brick-rsp';

/**
 * brick rpc req header
 */
export const HEADER_BRICK_REQ_KEY = 'x-brick-req';

/**
 * 后端内部调用头用户uid
 */
export const HEADER_BRICK_UID_KEY = 'x-brick-uid';

/**
 * 服务端返回数据缓存时间，为缓存毫秒数，客户端拿到数据会在这段时间内拿到缓存数据
 */
export const HEADER_CACHE_EXPIRED_KEY = 'x-cache-ctrl';

/**
 服务端接口评分
 小于60，标红
 60-80，标黄
 80及以上，绿
 */
export const HEADER_API_PERF_KEY = 'x-perf-score';

/**
 * 后端接口调用链路
 */
export const HEADER_API_PERF_TRACE_KEY = 'x-perf-trace-list';

/**
 * 编译模式
 */
export enum ENV_MODE {
  Production = 'production',
  Staging = 'staging',
  Development = 'development',
}
