import React from 'react';
import { Cascader } from 'antd';
import { CascaderProps } from 'antd/es/cascader';

import { getChainAreaTree, ChainAreaTreeItem } from '@pinweb/services';

const AntAreaCascader: React.FC<CascaderProps> = React.forwardRef<Cascader, CascaderProps>(
  (props, ref) => {
    const [options, setOptions] = React.useState<ChainAreaTreeItem[]>([]);
    React.useEffect(() => {
      getChainAreaTree().then(setOptions);
    }, []);
    return (
      <Cascader
        ref={ref}
        fieldNames={{ label: 'name', value: 'code' }}
        placeholder="请选择地区"
        showSearch
        // @ts-expect-error
        options={options}
        {...props}
      />
    );
  }
);

export default AntAreaCascader;
