import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { ColumnType } from 'antd/es/table';

import { useFetchList } from '@pinweb/react-utils';

import { SchemaTableRefObject, SchemaTableProps } from './interface';
import { defaultHandleProcessRspList } from './_utils';
import { SchemaComponent } from '../core';
import { connect } from '../store';
import { SchemaProviderState } from '../provider';

type StoreState = { schemaConfig?: SchemaProviderState['schemaConfig'] };

const SchemaTable = React.forwardRef<SchemaTableRefObject<any>, SchemaTableProps<any> & StoreState>(
  (props, ref) => {
    const {
      _columns = [],
      _fetchData,
      _pinServices,
      _processDataSource = defaultHandleProcessRspList,
      children,
      onUpdate,
      _slot = '',
      schemaConfig,
      ...extraProps
    } = props;

    const columns = React.useMemo(
      () =>
        _columns!
          .filter(v => v)
          .map(item => {
            const {
              key,
              title,
              dataIndex,
              _render,
              _dateFormat,
              valueEnum,
              ...componentProps
            } = item;
            const column: ColumnType<any> & any = {
              ...componentProps,
              title: title!,
              key: key!,
              dataIndex: key!,
            };
            if (item._render) {
              column.render = item._render;
            } else if (item._component || item._children) {
              column.render = (...args: any[]) => {
                return <SchemaComponent {...componentProps} args={args} />;
              };
            } else if (
              _dateFormat === true ||
              (typeof _dateFormat === 'string' && _dateFormat.length)
            ) {
              // TODO 这一坨应该找个地方放放
              column.render = (ts: string | number) => {
                ts = +ts || 0;
                if (ts && ts.toString().length === 10) {
                  ts *= 1000;
                }
                return ts ? (
                  <div style={{ whiteSpace: 'pre' }}>
                    {moment(ts).format(_dateFormat === true ? 'YYYY-MM-DD HH:mm:ss' : _dateFormat)}
                  </div>
                ) : (
                  '-'
                );
              };
            } else if (valueEnum) {
              column.render = (value: any) => {
                if (valueEnum instanceof Map) {
                  return valueEnum.get(value) || '-';
                }
                return valueEnum[value] || '-';
              };
            }
            return column as ColumnType<any>;
          }),
      [_columns]
    );
    const [customData, setCustomData] = React.useState([]);
    const [fetchList, rsp, loading, , total] = useFetchList<any, any>(
      _pinServices?.fn,
      _pinServices?.defaultReq,
      _pinServices?.requestOptions,
      _pinServices?.limit
    );
    const [dataSource, setDataSource] = React.useState<any[]>(props.dataSource || []);
    // const [scrollY, setScrollY] = React.useState(0);

    React.useEffect(() => {
      let data: any[] = [];
      try {
        if (customData && customData.length) {
          data = _processDataSource(customData);
        } else if (rsp) {
          data = _processDataSource(rsp);
        }
      } catch (e) {
        console.error('[schemaTable] _processDataSource error', e);
      }
      if (data instanceof Array) {
        setDataSource(data);
      } else {
        console.error(
          '[schemaTable] dataSource is an Array, please use _processDataSource return Array'
        );
      }
    }, [_processDataSource, customData, rsp]);

    /** priority use _fetchData **/
    const fetchData = React.useCallback(() => {
      if (_fetchData) {
        return Promise.resolve<any>(_fetchData!.call(null))
          .then(setCustomData)
          .catch(err => {
            return Promise.reject(err);
          });
      } else {
        _pinServices?.beforeFetchData && _pinServices.beforeFetchData();
        return fetchList.getList().catch((err: any) => {
          return Promise.reject(err);
        });
      }
      // eslint-disable-next-line
    }, [_fetchData, _pinServices?.beforeFetchData, fetchList]);

    /** fetch data **/
    React.useEffect(() => {
      if (!props.dataSource) {
        fetchData().catch((e: any) => {
          console.log(e);
        });
      }
    }, [fetchData, props.dataSource]);

    const tableLoading = React.useMemo(() => (props.dataSource ? false : loading), [
      loading,
      props.dataSource,
    ]);

    /** emit state **/
    React.useEffect(() => {
      const { page, limit, total } = fetchList;
      onUpdate &&
        onUpdate({
          dataSource,
          page,
          limit,
          loading: tableLoading,
          total,
        });
      // eslint-disable-next-line
    }, [dataSource, fetchList.limit, fetchList.page, fetchList.total, onUpdate, tableLoading]);

    /** inject ref **/
    React.useImperativeHandle(ref, () => ({
      fetchList,
      fetchData,
      _columns,
      dataSource,
      setDataSource,
    }));

    return (
      <>
        {typeof _slot === 'string' && (_slot === '' || _slot === 'top') && children}
        {typeof _slot === 'object' && _slot['top']}
        <Table
          columns={columns}
          loading={tableLoading}
          dataSource={dataSource}
          {...extraProps}
          {...schemaConfig?.tableConfig}
          pagination={
            extraProps?.pagination === false
              ? false
              : total
              ? {
                  total,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: total => `共 ${total} 条`,
                  pageSize: fetchList.limit,
                  current: fetchList.page,
                  onChange(current: number, size?: number) {
                    fetchList.changePageLimitList(current, size!).catch(err => err);
                  },
                  // onShowSizeChange(current: number, size: number) {
                  //   fetchList.changePageLimitList(1, size!).catch(err => err);
                  // },
                  ...schemaConfig?.tableConfig?.pagination,
                  ...extraProps?.pagination,
                }
              : false
          }
        />
        {typeof _slot === 'string' && _slot === 'bottom' && children}
        {typeof _slot === 'object' && _slot['bottom']}
      </>
    );
  }
);

SchemaTable.defaultProps = {
  // 所有的数组组件必须绑定 key。在 Table 中，dataSource 和 columns 里的数据值都需要指定 key 值。对于 dataSource 默认将每列数据的 key 属性作为唯一的标识。
  // https://ant-design.gitee.io/components/table-cn/#API
  rowKey: (row: any) => {
    if ('id' in row) {
      return row.id;
    }
  },
};

SchemaTable.displayName = 'Pinweb.SchemaTable';

export default connect<StoreState, SchemaTableProps<any>, SchemaProviderState>(res => res, {
  forwardRef: true,
})(SchemaTable);
