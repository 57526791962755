import React from 'react';
import _ from 'lodash';
import { Popconfirm, Tooltip } from 'antd';

import { LoadingButton } from '../../ant-button';

import { SchemaButtonGroupProps, SchemaButtonGroupPropsButton } from './interface';

function warpEventProps(props: any, args: any[] = []) {
  const p = { ...props };
  if (_.has(p, 'onClick')) {
    p.onClick = p.onClick.bind(null, ...args);
  }
  if (_.has(p, 'onConfirm')) {
    p.onConfirm = p.onConfirm.bind(null, ...args);
  }
  if (_.has(p, 'onCancel')) {
    p.onCancel = p.onCancel.bind(null, ...args);
  }
  return p;
}

const ButtonGroup: React.FC<SchemaButtonGroupProps> = React.forwardRef<
  HTMLDivElement,
  SchemaButtonGroupProps
>((props, ref) => {
  const { buttons, args, spacing = 10, ...otherProps } = props;

  return (
    <div {...otherProps} ref={ref}>
      {(buttons || [])
        .filter(v => !!v)
        .map((v, i) => {
          const { popConfirm, tooltip, ...buttonProps } = v as SchemaButtonGroupPropsButton;

          const style: React.CSSProperties = {
            marginLeft: `${i ? spacing : 0}px`,
            ...buttonProps.style,
          };

          if (popConfirm) {
            return (
              <Popconfirm
                title={`确认${buttonProps.children || '操作'}?`}
                {...warpEventProps(popConfirm, args!)}
                key={i}
              >
                <LoadingButton {...warpEventProps(buttonProps, args!)} style={style} />
              </Popconfirm>
            );
          }

          if (tooltip) {
            return (
              <Tooltip key={i} {...tooltip}>
                <LoadingButton {...warpEventProps(buttonProps, args!)} style={style} />
              </Tooltip>
            );
          }

          return <LoadingButton key={i} {...warpEventProps(buttonProps, args!)} style={style} />;
        })}
    </div>
  );
});

ButtonGroup.defaultProps = {
  spacing: 10,
};

export default ButtonGroup;
