import React from 'react';
import { Radio } from 'antd';

import { SchemaRadioGroupProps } from './interface';

const SchemaRadioGroup: React.FC<SchemaRadioGroupProps> = React.forwardRef<
  HTMLDivElement,
  SchemaRadioGroupProps
>((props, ref) => {
  return <Radio.Group ref={ref as any} {...props} />;
});

export default SchemaRadioGroup;
