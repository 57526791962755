import { reaction, action } from 'mobx';
import { serialize, serializable, getDefaultModelSchema, update } from 'serializr';
import { IReactionDisposer } from 'mobx/lib/internal';

import * as Storage from './storage';
import { mergeObservables } from './merge-x';
import { types, Types } from './types';
import { persistObject } from './persist-object';

export function persist(
  type: Types,
  schema?: any
): (target: Object, key: string, baseDescriptor?: PropertyDescriptor) => void; // two
export function persist(target: Object, key: string, baseDescriptor?: PropertyDescriptor): void; // method decorator
export function persist(schema: Object): <T>(target: T) => T; // object
export function persist(...args: any[]): any {
  const [a, b] = args;
  if (a in types) {
    return serializable(types[a](b));
  } else if (args.length === 1) {
    return (target: any) => persistObject(target, a);
  } else {
    // eslint-disable-next-line prefer-spread
    return serializable.apply(null, args);
  }
}

export interface optionsType {
  storage?: any;
  jsonify?: boolean;
  debounce?: number;
  /** 自定义 persis key */
  customKey?: boolean;
}

export interface IHydrateResult<T> extends Promise<T> {
  rehydrate: () => IHydrateResult<T>;
  /**
   * 清理reaction 不写存储
   */
  dispose: IReactionDisposer;
  /**
   * 清除存储
   */
  clear: () => void;
}

export function create({
  storage = Storage as any,
  jsonify = true,
  debounce = 0,
}: optionsType = {}) {
  if (typeof localStorage !== 'undefined' && localStorage === storage) storage = Storage;
  return function hydrate<T extends Object>(
    key: string,
    store: T,
    initialState: any = {}
  ): IHydrateResult<T> {
    const schema = getDefaultModelSchema(store as any);

    function hydration() {
      const promise: IHydrateResult<T> = storage
        .getItem(key)
        .then((d: any) => (!jsonify ? d : JSON.parse(d)))
        .then(
          action(`[mobx-persist ${key}] LOAD_DATA`, (persisted: any) => {
            mergeObservables(store, initialState);
            if (persisted && typeof persisted === 'object') {
              // TODO 可以直接使用 mergeObservables 需要测试
              if (window && window.window === window) {
                if (schema) {
                  update(schema, store, persisted);
                }
              } else {
                mergeObservables(store, persisted);
              }
            }
            return store;
          })
        );
      promise.rehydrate = hydration;
      return promise;
    }

    const result = hydration();

    result.clear = () => {
      storage.removeItem(key);
    };

    if (schema) {
      result.dispose = reaction(
        () => serialize(schema, store),
        (data: any) => storage.setItem(key, !jsonify ? data : JSON.stringify(data)),
        {
          delay: debounce,
        }
      );
    } else {
      console.error('[mobx-persist] No cacheable value');
    }
    return result;
  };
}
