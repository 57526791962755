import { FormItemProps, FormProps } from 'antd/es/form';
import { ColProps } from 'antd/es/col';

import { SchemaComponentProps } from '..';

export const defaultLabelCol: ColProps = { span: 4 };
export const defaultWrapperCol: ColProps = { span: 12 };

export function getDefaultFormItemPropsComponentProps(
  formProps: FormProps = {},
  formItemProps: Partial<FormItemProps>,
  schema: SchemaComponentProps
): {
  defaultFormItemProps: Partial<FormItemProps>;
  defaultComponentProps: any;
} {
  const defaultFormItemProps: Partial<FormItemProps> = {};
  const defaultComponentProps: SchemaComponentProps = {};
  // 当label为空，form的labelCol不生效
  if (!formItemProps.label && !formProps.wrapperCol) {
    defaultFormItemProps.wrapperCol = {
      offset: formProps?.labelCol?.span || defaultLabelCol.span,
    };
  }
  if (schema?._component && typeof schema?._component === 'string') {
    if ([/input/i].some(v => v.test(schema._component as string))) {
      defaultComponentProps.placeholder = `请输入${formItemProps.label || ''}`;
    } else if ([/select/i].some(v => v.test(schema._component as string))) {
      defaultComponentProps.placeholder = `请选择${formItemProps.label || ''}`;
    }
    if (schema._component === 'ant-checkbox') {
      defaultFormItemProps.valuePropName = 'checked';
    }
  }
  return { defaultComponentProps, defaultFormItemProps };
}
