import { getGlobalObject, isPropertyExist, isPrimitive } from '../../../utils';
import { getCurrentHub, PREFIX, ICarrier } from '../../../core';
import {
  exceptionHandler,
  handleErrorWithoutStack,
  handleInCompleteRejection,
  handleRejection,
} from '../exceptionHandler';

export function globalErrorHandler() {
  const globalObject = getGlobalObject<ICarrier & Window>();
  if (isPropertyExist(globalObject[PREFIX], 'OLDONERROR')) {
    return;
  }
  globalObject[PREFIX].OLDONERROR = globalObject.onerror;
  getGlobalObject<Window>().onerror = function (
    event: Event | string,
    source?: string,
    lineno?: number,
    colno?: number,
    error?: Error
  ) {
    // 特殊浏览器没有col
    colno = colno || 0;

    // 处理浏览器error
    const exception = isPrimitive(error)
      ? handleErrorWithoutStack(event)
      : exceptionHandler(error!);

    getCurrentHub().captureMessage(exception);

    globalObject[PREFIX].OLDONERROR &&
      globalObject[PREFIX].OLDONERROR.call(null, event, source, lineno, colno, error);
  };
}

export function globalUnhandledRejectionHandler(): void {
  const globalObject = getGlobalObject<ICarrier & Window>();
  if (isPropertyExist(globalObject[PREFIX], 'OLDONUNHANDLEDREJECTION')) {
    return;
  }
  globalObject[PREFIX].OLDONUNHANDLEDREJECTION = globalObject.onunhandledrejection;

  getGlobalObject<Window>().onunhandledrejection = function (e: any) {
    let error = e;
    try {
      error = e && 'reason' in e ? e.reason : e;
    } catch (_oO) {
      // no-empty
    }

    if (error) {
      const exception = isPrimitive(error)
        ? handleInCompleteRejection(error)
        : handleRejection(error);

      const currentHub = getCurrentHub();
      currentHub.captureMessage(exception);
    }

    globalObject[PREFIX].OLDONUNHANDLEDREJECTION &&
      globalObject[PREFIX].OLDONUNHANDLEDREJECTION.call(null, e);
  };
}

export function addSystemInfoEXT() {
  const info = window.navigator.userAgent;
  getCurrentHub().addExt('userAgent', info);
}
