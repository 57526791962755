import _ from 'lodash';

import { Rbacv2Services } from '@pinweb/proto-api-client';

export type ModelMenuItem<EX = { [key: string]: any }> = Omit<rbacv2.ModelMenu, 'ex'> & {
  children?: ModelMenuItem[];
  ex: {
    /**
     * 是否显示菜单
     * 当有子节点，但是都是hide 父亲也不显示
     * @default true
     **/
    hidden?: boolean;

    /**
     * 面包屑关联的menu id，
     * 兼容旧数据（旧的因为menu id 不同环境同一菜单id不一致）
     * @deprecated
     */
    breadcrumbIds?: number[];

    /**
     * 面包屑关联的前端静态id，各个环境是一致的
     */
    breadcrumbConstIds?: string[];

    /**
     * 面包屑对应的菜单数据
     * TODO 支持自定义
     */
    breadcrumb?: ModelMenuItem[];

    /**
     * 是否显示面包屑
     * 默认按照菜单子父关联
     */
    hideBreadcrumb?: boolean;

    /**
     * 当前菜单激活，额外需要被激活的菜单
     * 例如去到详情页面，激活同级列表菜单
     */
    defaultSelectedKeys?: string[];
  } & EX;
};

/**
 * 根据 menuList 生成树 根据view_order正序
 * 有下级菜单才会出现children属性
 * @param menuList
 * @param authMenuIds 带有这个只构建包含在哪的tree, 不传则全部
 * @param transform 这里建议做些ex的转换，保持 ModelMenuItem 返回数据
 */
export function generateMenuTree<T extends ModelMenuItem = ModelMenuItem>(
  menuList: rbacv2.ModelMenu[],
  authMenuIds: number[] = [],
  transform?: (menu: ModelMenuItem, menuList: ModelMenuItem[]) => T
): T[] {
  const menuMap = menuList.reduce<Record<number, ModelMenuItem>>((acc, menu) => {
    const { ex, ...params } = menu;
    acc[menu.id!] = {
      ...params,
      ex: (() => {
        try {
          return JSON.parse(ex!);
        } catch (e) {
          return {};
        }
      })(),
    };
    return acc;
  }, {});

  const tmpMenuList = _.cloneDeep(Object.values(menuMap));
  tmpMenuList.forEach(menu => {
    // 处理面包屑
    if (_.has(menu.ex, 'breadcrumbIds') && menu.ex.breadcrumbIds!.length) {
      menuMap[menu.id!].ex.breadcrumb = menu.ex.breadcrumbIds!.reduce<ModelMenuItem[]>(
        (acc, id) => {
          const item = tmpMenuList.find(v => v.id! === id);
          if (item) acc.push(item);
          return acc;
        },
        []
      );
    } else if (_.has(menu.ex, 'breadcrumbConstIds') && menu.ex.breadcrumbConstIds!.length) {
      menuMap[menu.id!].ex.breadcrumb = menu.ex.breadcrumbConstIds!.reduce<ModelMenuItem[]>(
        (acc, id) => {
          const item = tmpMenuList.find(v => v.const_id! === id);
          if (item) acc.push(item);
          return acc;
        },
        []
      );
    }
    // 自定义转换 这里id pid结构不可以破坏
    if (transform)
      menuMap[menu.id!] = (transform(menuMap[menu.id!], tmpMenuList) as unknown) as ModelMenuItem;
  });

  const sortFn = (o: ModelMenuItem, n: ModelMenuItem) => {
    return o.view_order! - n.view_order!;
  };

  const menuTree = Object.values(menuMap)
    .reduce<ModelMenuItem[]>((acc, menu) => {
      if (_.has(menuMap, menu.parent_id!)) {
        menuMap[menu.parent_id!].children = [
          ...(menuMap[menu.parent_id!]?.children || []),
          menu,
        ].sort(sortFn);
      } else {
        acc.push(menu);
      }
      return acc;
    }, [])
    .sort(sortFn);

  if (authMenuIds && authMenuIds.length) {
    const ids = new Set(authMenuIds);
    const getParentMenu = (id: number) => {
      if (_.has(menuMap, id)) {
        getParentMenu(menuMap[id].parent_id!);
        ids.add(id);
      } else {
        ids.delete(id);
      }
    };

    ids.forEach(getParentMenu);

    const filterMenuList = (menus: ModelMenuItem[]) =>
      menus.filter(menu => {
        if (ids.has(menu.id!)) {
          // 过滤孙子节点
          if (menu.children) {
            const children = filterMenuList(menu.children);

            // 最末节点保留children
            if (children.length) {
              menu.children = children;
            }
          }
          return true;
        } else {
          return false;
        }
      });

    // 为了支持泛型
    return (filterMenuList(menuTree) as unknown) as T[];
  }

  return (menuTree as unknown) as T[];
}

/**
 * 获取当前项目，拥有权限的菜单
 * @param authMenuIds
 */
export async function fetchMenuTree(authMenuIds: number[] = []): Promise<ModelMenuItem[]> {
  const { menu_list } = await Rbacv2Services.GetMenuTree({
    menu_tree_id: process.env.appId,
    corp_id: process.env.corpId,
  });
  return generateMenuTree(menu_list!, authMenuIds);
}

/**
 * 过滤菜单树不符合条件的元素
 * @param menuTree
 * @param filter
 */
export const filterMenuTree = (
  menuTree: ModelMenuItem[],
  filter: (menu: ModelMenuItem) => boolean
): ModelMenuItem[] => {
  const filterMenuTree = (list: ModelMenuItem[]): ModelMenuItem[] =>
    list.filter(filter).map(v => {
      const { children, ...rest } = v;
      const res = filterMenuTree(children ?? []);
      return {
        ...rest,
        ...(res?.length ? { children: res } : undefined),
      };
    });
  return filterMenuTree(menuTree);
};

/**
 * 扁平化 menuTree 为Map
 * @param menuTree
 * @param key
 * @param childrenKeyPath
 */
export const flatMenuTree = (
  menuTree: ModelMenuItem[],
  key: keyof ModelMenuItem,
  childrenKeyPath: string[] = ['children']
): Record<string, ModelMenuItem> => {
  return menuTree.reduce<Record<string, ModelMenuItem>>((acc, menu) => {
    const flatMenu = (item: ModelMenuItem) => {
      acc[String(item[key])] = item;
      childrenKeyPath.forEach(childKey => {
        const child = _.get(item, childKey, []);
        child.map((v: ModelMenuItem) => flatMenu(v));
      });
    };
    flatMenu(menu);
    return acc;
  }, {});
};
