import { ErrCode } from '@pinweb/services';
import { message } from 'antd';

const MsgMap = {};

export default function errorMessage(code: number, msg: string) {
  const errMsg = MsgMap[code] || msg;

  message.error({
    content: errMsg,
    key: errMsg,
  });
}
