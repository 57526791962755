import { StackFrame, StackTrace } from '../../core/types/stacktrace';
import { isPrimitive } from '../../utils';

export function computeStackTrace(ex: any): StackTrace | null {
  const stack: string = isPrimitive(ex) ? ex : ex.stack;
  if (!stack) {
    return null;
  }
  const lines = stack.split('\n');
  const trace: StackTrace = {
    name: lines[0],
    message: ex.message || lines[1],
    stack: [],
  };
  lines.slice(ex.message ? 1 : 2).forEach(line => {
    const func = line.match(/(?=at ).*(?=\W\()/g);
    const atLine = line.match(/\((\S*)\)/);
    if (atLine && atLine[1]) {
      const [l = 0, c = 0] = atLine[1].split(':').slice(-2);
      const el: StackFrame = {
        url: atLine[1].slice(0, atLine[1].length - `:${l}:${c}`.length),
        column: +c,
        line: +l,
      };
      if (func && func.length) {
        el.func = func[0];
      }
      trace.stack!.push(el);
    } else {
      if (line.trim().startsWith('at ')) {
        const url = line.trim().slice(3);
        const [l = 0, c = 0] = url.split(':').slice(-2);
        const el: StackFrame = {
          url: url.slice(0, url.length - `:${l}:${c}`.length),
          column: +c,
          line: +l,
        };
        trace.stack!.push(el);
      }
    }
  });
  return trace.name ? trace : null;
}
